// MiddleNavChild.jsx

import React from 'react';
import './MiddleNavChild.scss';
import { urlApi } from '../Context/url';


const MiddleNavChild = ({ middleNav }) => {
  return (
    <>
      {middleNav.map((data, index) => (
        <div key={index} className="item1">
          <div className="item-content">
            <img src={`${urlApi}/${data.logo}`} alt="logo" className='logo'/>
            <div>
              <span className='sub0'>{data.name.slice(0, 20)}</span>
              <div class="line-break"></div> 
              <span className='sub1'>{data.name.slice(20, 32)}</span>
              <div class="line-break"></div> 
              <span className='sub2'>{data.name.slice(32)}</span>
            </div>
            <div>
              <img src={`${urlApi}/${data.flag}`} alt="flag" className='logo1'/>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default MiddleNavChild;

