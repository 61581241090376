import { useState, useEffect } from "react";
import WCUCHILD from "./wcuchild";
import { urlApi } from '../../Context/url';
import { toast } from 'react-toastify';
import { getToken } from "../LocalStorageService";

const WCU = () => {
    const [formData, setFormData] = useState({
        name: '',
        body: '',
    });


///////////////////////////////////////////////////////////////////////////////
   // for put
   const [formData1, setFormData1] = useState({
    name1: '',
    body1: '',
});
  


   const [showbanner, setShowBanner] = useState([])
   const [put, setPut] = useState([])
   
   // for pagination
   const [currentPage, setCurrentPage] = useState(1);
   const bannersPerPage = 6; // You can adjust this based on your preference

   
 // for pagination
 // Calculate indexes for pagination
 const indexOfLastBanner = currentPage * bannersPerPage;
 const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
 const currentBanners = showbanner.slice(indexOfFirstBanner, indexOfLastBanner);

 // Change page
 const paginate = (pageNumber) => setCurrentPage(pageNumber);


 //  
// Add this function to generate pagination buttons dynamically
const generatePaginationButtons = () => {
 const totalPages = Math.ceil(showbanner.length / bannersPerPage);
 const visiblePages = 18; // Number of visible pagination buttons

 let startPage = 1;
 let endPage = totalPages;

 if (totalPages > visiblePages) {
   const half = Math.floor(visiblePages / 2);
   startPage = Math.max(currentPage - half, 1);
   endPage = Math.min(startPage + visiblePages - 1, totalPages);
   if (endPage - startPage + 1 < visiblePages) {
     startPage = endPage - visiblePages + 1;
   }
 }

 const buttons = [];
 for (let i = startPage; i <= endPage; i++) {
   buttons.push(
     <button
       key={i}
       onClick={() => paginate(i)}
       style={{ fontWeight: currentPage === i ? 'bold' : 'normal', color: currentPage === i ? 'red' : 'white', }}
     >
       {i}
     </button>
   );
 }

 return buttons;
};


 // put request
 const putHandler = (data) => {
   setPut(data);
   // putBanner(data.id);
 }


 const putBanner = async () => {
   const accessToken = getToken().access_token
   try {
     // formData.append('text', text1);   // Append text value
      const formDatappend = new FormData();
      if (formData1.name1 && formData1.name1.trim() !== '') {
        formDatappend.append('name', formData1.name1);
      }
      if (formData1.body1 && formData1.body1.trim() !== '') {
        formDatappend.append('body', formData1.body1);
      }


     const response = await fetch(`${urlApi}/whychooseus/${put.id}/`, {
       method: 'PUT',
       body: formDatappend,
       headers: {
         'Authorization': `Bearer ${accessToken}`,
     },
     });

     if (response.ok) {
       console.log('Data saved successfully');
       toast.success('Saved!');
        // Clear the form fields after a successful submission
        setFormData1([]);
        // Update the state with the modified banner
        const updatedShowBanner = showbanner.map(item =>
         item.id === put.id ? { ...item, name: formData1.name1, body: formData1.body1 } : item
         );
         
         setShowBanner(updatedShowBanner);
         setPut([]); // Clear the 'put' state
         await fetchBanners(); // Fetch banners after deleting to update the list

     } else {
       // const errorMessage = await response.text();
       // console.error('Error saving data:', errorMessage);
       // notifyError('Required fields not filled.', errorMessage);
       const errorMessage = await response.text();
       console.error('Error saving data:', errorMessage);
       toast.error('Required fields not filled.', errorMessage);
     }
   } catch (error) {
     console.error('Error saving data:', error.message);
     toast.error('Error saving data', error.message);
   }
       
     }



 // Delete handler
 const deleteHandler = (data) => {
   deleteBanner(data);
 }

 // for delete banner

 const deleteBanner = async (id) => {
   const accessToken = getToken().access_token
   try {
     const response = await fetch(`${urlApi}/whychooseus/${id}/`, {
       method: 'DELETE',
       headers: {
         'Authorization': `Bearer ${accessToken}`,
     },
     });
     if (response.ok) {
       console.log('Data deleted successfully');
       toast.success('Deleted!');
       await fetchBanners(); // Fetch banners after deleting to update the list
     } else {
       const errorMessage = await response.text();
       console.error('Error saving data:', errorMessage);
       toast.error('Required fields not filled.', errorMessage);
     }
   } catch (error) {
     console.error('Error deleting data:', error.message);
     toast.success('Error deleting data', error.message);
   }
       
     }
   

 // for get request
 const fetchBanners = async () => {
   try {
     const response = await fetch(`${urlApi}/whychooseus/`);
     if(response.ok) {
       const data = await response.json();
       setShowBanner(data);
       
       
     } else {
       console.log('Error fetching banners')
     }
   } catch(error) {
     console.log('Error fetching banners:', error.message);
   }
 }

 useEffect(() => {
   const fetchAndSetBanners = async () => {
     await fetchBanners();
     setCurrentPage(1);
   };
   fetchAndSetBanners();
 }, []);


 const handleContentChange1 = (e) => {
    setFormData1({ ...formData1, [e.target.name]: e.target.value });
  }
///////////////////////////////////////////////////////////////////




    const handleChange = (e, property) => {
        setFormData({ ...formData, [property]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const accessToken = localStorage.getItem('accessToken');
        const accessToken = getToken().access_token
        try {
            const formDataObject = new FormData();
            formDataObject.append('name', formData.name);
            formDataObject.append('body', formData.body);

            const response = await fetch(`${urlApi}/whychooseus/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formDataObject,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            toast.success('Data added successfully');
            fetchBanners(); // Fetch banners after deleting to update the list
        } catch (error) {
            console.log('Error in data add', error);
            toast.error('Error adding data');
        }
    }

    return <WCUCHILD formData={formData} handleSubmit={handleSubmit} handleChange={handleChange} currentBanners={currentBanners} handleContentChange1={handleContentChange1} putHandler={putHandler} deleteHandler={deleteHandler} put={put} putBanner={putBanner} formData1={formData1} showbanner={showbanner} currentPage={currentPage} bannersPerPage={bannersPerPage} generatePaginationButtons={generatePaginationButtons} paginate={paginate}/>
}
export default WCU;
