// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  background-color: none !important;
}

#child {
  background-color: none !important;
}

#main {
  background-color: none !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/Editor.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,iBAAA;EACA,iCAAA;AACJ;;AAEA;EACI,iCAAA;AACJ;;AAME;EACE,iCAAA;AAHJ","sourcesContent":["#main {\n    display: flex;\n    flex-wrap: wrap;\n    padding-top: 1rem;\n    background-color: none !important;\n}\n\n#child {\n    background-color: none !important;\n    // flex: 1 0 calc(33.33% - 20px); /* Adjust width as needed, subtracting margin */\n    // margin: 5px; /* Add margin as needed for spacing */\n    // box-sizing: border-box; /* Ensure padding and border are included in the width */\n    // /* Add your styles for the child element here */\n  }\n\n  #main {\n    background-color: none !important;\n  \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
