import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlApi } from '../../Context/url';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import AdminPanel from '../AdminPanel';
import Add from './Add';

import {getToken, removeToken, storeToken} from '../LocalStorageService';

const LoginChild = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false); 

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${urlApi}/login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });
            if (!response.ok) {
                throw new Error('Login failed');
            }
            toast.success('Login Successful');
            const data = await response.json();
            // localStorage.setItem('accessToken', data.access);
            // localStorage.setItem('refreshToken', data.refresh);
            storeToken(data)
            setIsLoggedIn(true);
        } catch (error) {
            console.error('Login failed', error);
            toast.error('Wrong username or password');
        }
    }

    const handleLogout = () => {
        // localStorage.removeItem('accessToken');
        // localStorage.removeItem('refreshToken');
        removeToken()
        setIsLoggedIn(false);
        navigate('/')
    }

    return (
        <>
            {isLoggedIn || getToken().access_token ? (
                <AdminPanel handleLogout={handleLogout} />
            ) : (
                <Add
                    handleLogin={handleLogin}
                    handleLogout={handleLogout}
                    email={setEmail}
                    password={password}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    ToastContainer={ToastContainer}
                />
            )}
        </>
    );
}

export default LoginChild;
