// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-snow .ql-editor img {
  max-width: 90%;
  max-height: 50%;
  object-fit: cover;
  line-height: 1.6rem;
}

.ql-toolbar.ql-snow .ql-picker-label {
  padding-top: 0 !important;
}

#container {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 4.5rem;
}

.btncontainer #bnbtn1 {
  margin-top: 10px;
  background-color: #0467a0;
  padding: 12px;
  border: 0;
  border-radius: 2px;
  color: white;
  padding-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/Blog/Editor.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,UAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,SAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;AACJ","sourcesContent":[".ql-snow .ql-editor img {\n    max-width: 90%;\n    max-height: 50%;\n    object-fit: cover;\n    line-height: 1.6rem;\n}\n\n.ql-toolbar.ql-snow .ql-picker-label { \n    padding-top: 0 !important;\n}\n\n#container {\n    display: flex;\n    flex-direction: column;\n    width: 60%;\n    margin-left: 4.5rem;\n}\n\n.btncontainer #bnbtn1 {\n    margin-top: 10px;\n    background-color: #0467a0;\n    padding: 12px;\n    border: 0;\n    border-radius: 2px;\n    color: white;\n    padding-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
