import React, { useState } from "react";
import MyEditor from "./Editor";
import './AdminPanel.scss';
import Banner from "./Banner";
import GalleryImage from "./GalleryImage";
import UniversityCampusAround from "./UniversityCampusAround";
import Events from "./UpcommingEvents/Events";
import Noticess from "./Notice/Notices";
import WCU from "./WhyChooseUs/wcu";
import Blog from "./Blog/blog";
import Wetefe from "./Wetefe/Wetefe";
import Testiomonial from "./Testiomonial/Testiomonial";

import background from './content_filling_4-PhotoRoom.png-PhotoRoom.png';
import Academic from "./AcademicPartner/Academi";
import Faculties from "./Faculties/Faculties";




const AdminPanel = ({handleLogout}) => {
    const [left, setLeft] = useState(false);
    const [showContent2, setShowContent2] = useState(false);
    const [ShowContent3, setShowContent3] = useState(false);
    const [university, setUniversity] = useState(false);
    const [events, setEvents] = useState(false);
    const [wcu, setWcu] = useState(false);
    const [blog, setBlog] = useState(false);
    const [watefe, setWatefe] = useState(false);
    const [testomonial, setTestomonial] = useState(false);
    const [partner, setPartner] = useState(false);
    const [faculties, setFaculties] = useState(false);
    const [notices, setNotices] = useState(false);

    const handleShow = (e) => {
        e.preventDefault();
        setLeft(!left);
        setShowContent2(false); // Close content2 when clicking on "Child Banner"
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false);
        setFaculties(false);
        setNotices(false);

    }

    const handleShowContent2 = () => {
        setShowContent2(!showContent2);
        setLeft(false); // Close MyEditor when clicking on "Banner"
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false);
        setFaculties(false);
        setNotices(false);

    }
    const handleShowContent3 = () => {
        setShowContent3(!ShowContent3);
        setShowContent2(false);
        setLeft(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false);
        setFaculties(false);
        setNotices(false);
    }

    const handleUniversity = () => {
        setUniversity(!university);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false)
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false);
        setFaculties(false);
        setNotices(false);
    }

    const handleEvents = () => {
        setEvents(!events);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false);
        setUniversity(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false);
        setFaculties(false);
        setNotices(false);
    }

    const hadleWcu = () => {
        setWcu(!wcu);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false);
        setFaculties(false);
        setNotices(false);

    }

    const handleBlog = () => {
        setBlog(!blog);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false);
        setFaculties(false);
        setNotices(false);

    }

    const handleWatefe = () => {
        setWatefe(!watefe);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setTestomonial(false)
        setPartner(false);
        setFaculties(false);
        setNotices(false);

    }
    const TestomonialHandle = () => {
        setTestomonial(!testomonial);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setPartner(false);
        setFaculties(false);
        setNotices(false);
    }

    const PartnerHandle = () => {
        setPartner(!partner);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setFaculties(false);
        setNotices(false);
    }

    const FacultyHanlder = () => {
        setFaculties(!faculties);
        setPartner(false);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false)
        setNotices(false);
    }

    const NoticeHanlder = () => {
        setNotices(!notices)
        setPartner(false);
        setShowContent2(false);
        setLeft(false);
        setShowContent3(false);
        setUniversity(false);
        setEvents(false);
        setWcu(false);
        setBlog(false);
        setWatefe(false);
        setTestomonial(false);
        setPartner(false);
        setFaculties(false);
    }

    return (
        <div className={`admin-panel ${left ? 'hide-scrollbar' : ''}`}>
            <div className="leftchild">
                <p className="pd" id='ppd' onClick={handleLogout}>Logout</p>
                <p className="pd" onClick={handleShowContent2}>Banner</p>
                <p className="pd" onClick={handleShow}>Child Banner</p>
                <p className="pd" onClick={handleShowContent3}>Gallery</p>
                <p className="pd" onClick={handleUniversity}>UCAN</p> 
                <p className="pd" onClick={handleEvents}>Events</p>
                <p className="pd" onClick={hadleWcu}>WCU</p>
                <p className="pd" onClick={handleBlog}>Blog</p>
                <p className="pd" onClick={handleWatefe}>WBEFE</p>
                <p className="pd" onClick={TestomonialHandle}>Testimonial</p>
                <p className="pd" onClick={PartnerHandle}>Partners</p>
                <p className="pd" onClick={FacultyHanlder}>Faculties</p>
                <p className="pd" onClick={NoticeHanlder}>Notices</p>

            </div>
            <div className="leftchildcontent">
            {!left && !showContent2 && !ShowContent3 && !university && !events && !wcu && !blog && !watefe && !testomonial && !partner && !faculties && !notices &&(
            <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: '100vh', backgroundColor: '#9cdcf8' }}>
            </div>
            )}
                {left && (
                    <MyEditor />
                )}
                {showContent2 && (
                    <Banner />
                )}
                {ShowContent3 && (
                    <GalleryImage/>
                )}
                {university && (
                    <UniversityCampusAround/>
                )}
                {events && (
                    <Events/>
                )}
                {wcu && (
                    <WCU/>
                )}
                {
                    blog && (
                        <Blog/>
                    )
                }
                {
                    watefe && (
                        <Wetefe/>
                    )
                }
                {
                    testomonial && (
                        <Testiomonial/>
                    )
                }
                {
                    partner && (
                        <Academic/>
                    )
                }
                {
                    faculties && (
                        <Faculties/>
                    )
                }
                {
                    notices && (
                        <Noticess/>
                    )
                }
            </div>
        </div>
    );
}

export default AdminPanel;
