import { urlApi } from '../Context/url';
import { useState, useCallback } from 'react';
import { TfiShiftLeftAlt, TfiShiftRightAlt } from "react-icons/tfi";
import { RxCross1 } from "react-icons/rx";
import './EventChild.scss';
import BuildingImage from '../BottomNav/MUBuilding.jpg';

const NoticeChild = ({ event }) => {
  const [pop, setPop] = useState(false);
  const [pop2, setPop2] = useState(false);
  const [popdata, setPopdata] = useState([]);
  const [vpopdata, setvPopdata] = useState([]);
  const [header, setHeader] = useState([]);
  const [vheader, setvHeader] = useState([]);
  const [viewPop, setViewPop] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = event.length;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedItems =
    totalItems > itemsPerPage
      ? event.slice(startIndex, endIndex)
      : event;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
  }, [totalPages]);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  const popUpHandler = (body, name) => {
    setPopdata(body);
    setHeader(name);
    setPop(true);
    setViewPop(false);
    
  };

  const closePopUp = () => {
    setPop(false);
  };

  const viewAll = () => {
    setViewPop(true);
    setPop(false);
    
  };

  const closeViewPop = () => {
    setViewPop(false);
    setSelectedItemIndex(-1);
    setPop(false);
  };

  const viewPopUpHandler = (body, name, index) => {
    setvPopdata(body);
    setvHeader(name);
    setPop2(true);
    setSelectedItemIndex(index);
  };
  const closePopUp2 = () => {
    setPop2(false);
  };


  if (!event || event.length === 0) {
    return (
      <>
        <h1 className='h1text'>Event</h1>
        <p>Not available</p>
      </>
    );
  }

  return (
    <>
    <br/>
      <div className="parent-box">
        <div className="child-box1" id='main1'>
          <h1>Notices & Upcoming Events</h1>
          <button onClick={viewAll}>View All</button>
        </div>

        {displayedItems.map((data, index) => (
          <div className="child-box" key={index}>
             {index === 0 && <TfiShiftLeftAlt className='right-shift-data1' onClick={handlePrevPage} />}
            <img src={`${urlApi}/${data.img}`} alt="img1" onClick={() => popUpHandler(data.body, data.name)} />
            <p>{data.name}</p>
            {index === displayedItems.length - 1 && <TfiShiftRightAlt className='left-shift-data1' onClick={handleNextPage} />}
          </div>
        ))}
        {/* <TfiShiftLeftAlt className='right-shift-data1' onClick={handlePrevPage} />
        <TfiShiftRightAlt className='left-shift-data1' onClick={handleNextPage} /> */}
      </div>

      {pop && (
        <div className="overlay" onClick={closePopUp}>
           {/* <img src={BuildingImage} alt="hi"  className="imginsidediv"/> */}
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closePopUp}><RxCross1 className="crossbtn" /></span>
            <span className="htext-keep-moving"><p>{header}</p></span>
            <p dangerouslySetInnerHTML={{ __html: popdata }}></p>
          </div>
        </div>
      )}

      {viewPop && (
        <>
        <div className="overlay" onClick={closeViewPop}>
          <div className="popup small-popup" onClick={(e) => e.stopPropagation()}>
            {event.map((data, index) => (
              <div
              className={`child-box small-box ${selectedItemIndex === index ? 'active' : ''}`}
              key={index}
              >
                <img
                  src={`${urlApi}/${data.img}`}
                  alt="img1"
                  onClick={() => viewPopUpHandler(data.body, data.name, index)}
                />
                <p>{data.name}</p>
              </div>
            ))}
           
          <span onClick={closeViewPop}><RxCross1 className="cross-btn" /></span>
          </div>
        </div>
       </>
      )}
      {pop2 && (
        <div className="overlay" onClick={closePopUp2}>
            {/* <img src={BuildingImage} alt="hi"  className="imginsidediv"/> */}
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closePopUp2}><RxCross1 className="crossbtn" /></span>
            <span className="htext-keep-moving"><p>{vheader}</p></span>
            <p dangerouslySetInnerHTML={{ __html: vpopdata }}></p>
          </div>
        </div>
      )}

    </>
  );
};

export default NoticeChild;
