import { urlApi } from "../Context/url";
import "./BannerSideContent2.scss";
import { useEffect, useState, useCallback } from 'react';
import { TfiShiftLeftAlt, TfiShiftRightAlt  } from "react-icons/tfi";

const BannerSideContent2 = ({bannerdata}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

    // const handleShiftLeft = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonial.length) % testimonial.length);
    // };

    const handleShiftRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerdata.length);
    };

    

      const handleShiftLeft = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerdata.length);
      }, [bannerdata.length]);
    
      useEffect(() => {
        const intervalId = setInterval(handleShiftRight, 5000);
        return () => clearInterval(intervalId);
      }, [handleShiftLeft]);

      if (!bannerdata || bannerdata.length === 0) {
        return (
          <>
            <h1 className='h1text'>Banner Data</h1>
            <p>No data available</p>
          </>
        );
      }
  return (
    <>
      <div className="banner-slide" id="img-container">
        <img
          src={`${urlApi}/${bannerdata[currentIndex].img}`}
          alt="img"
        />
      </div>
      <div
        className="r-title main-color-1 font-2"
        style={{
          transition: "none 0s ease 0s",
          textAlign: "inherit",
          lineHeight: "13px",
          borderWidth: "0px",
          margin: "0px 0px 3px",
          padding: "20px",
          letterSpacing: "0px",
          fontWeight: 700,
          fontSize: "24px",
        }}
      >
        <article className="spn">{bannerdata[currentIndex].header}</article>
        <p>
          {bannerdata[currentIndex].text}
        </p>
        <TfiShiftRightAlt className='left-shift' onClick={handleShiftRight}/>
        <TfiShiftLeftAlt  className='right-shift' onClick={handleShiftLeft}/>

      </div>
    </>
  );
};
export default BannerSideContent2;
