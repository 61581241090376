import { ToastContainer } from 'react-toastify';
import QuillEditor from './Editor';
import './Events.scss';
import Editor2 from './Editor2';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";

const Blogchild = ({clearEditor, handleSubmit, handleContentChange, formData, currentBanners, handleContentChange1, putHandler, deleteHandler, put, putBanner, formData1, showbanner, currentPage, bannersPerPage, generatePaginationButtons, paginate, clearImage}) => {
    return (
        <>    
    <br/>
    <div className='main-conatiner' id='container'>
        <div className='child-container'>
      <textarea
        type="text"
        className="form-input"
        placeholder='Header: No more than 30 characters only'
        value={formData.header}
        onChange={(e) => handleContentChange(e, 'header')}
      />
      </div>
      <div className='child-container'>
      <textarea
        type="text"
        className="form-input"
        placeholder='Long Header: No more than 200 characters only'
        value={formData.text}
        onChange={(e) => handleContentChange(e, 'text')}
      />
    </div>
    <div className='child-container'>
      <input
        type="file"
        className="form-input form-file-input"
        name='img'
        required
        onChange={handleContentChange}
        key={clearImage} // Add a key to the input to trigger re-render when clearImage changes
      />
      </div>
      <QuillEditor onContentChange={handleContentChange} clearEditor={clearEditor}/> 
      <div className='btncontainer'>
      <button id="bnbtn1" onClick={handleSubmit}>
        Save Content
      </button>
      <ToastContainer />
    </div>
    {/* start */}
  <article className='article-height'>
      {currentBanners.map((data, index)=> <div className='main-content'>
        <div className='child-main-container'>
          <div className='cont1'>
          <span>{index + 1}. </span>
          <span>{data.header}</span> 
          </div>
          <div className='cont2'>
          <FaEye /> <FaRegEdit onClick={()=> putHandler(data)}/> <RiDeleteBin5Fill onClick={()=> deleteHandler(data.id)}/>
          </div>
          </div>
      </div>)}
      </article>
      {put.id && (
        <div className='main-conatiner' id='main'>
        <div className='child-container' id='child'>
      <textarea
        type="text"
        name='header1'
        value={formData1.header1 || put.header}
        onChange={handleContentChange1}
        className="form-input"
        placeholder='Header: No more than 30 characters only'
      />
      </div>
    <div className='child-container' id='child'>
      <input
        type="file"
        name='img1'
        onChange={handleContentChange1}
        className="form-input form-file-input"
        required
      />
      </div>
      <div className='child-container' id='child'>
      <textarea
        type="text"
        name='text1'
        value={formData1.text1 || put.text}
        onChange={handleContentChange1}
        className="form-input"
        placeholder='Header: No more than 200 characters only'
      />
      </div>
      <div>
      </div>
      <div className='child-container' id='child'>
       <Editor2 onContentChange={handleContentChange1}
        theme="snow"
        name='body1'
        value1={formData1.body1 || put.body}
        placeholder="Start writing..."
        style={{height: '210px', objectFit: 'cover', marginBottom: '60px'}}
       />
      </div>
     
      <button  id="bnbtn" onClick={putBanner}>
        Save Content
      </button>
      <ToastContainer />
    
    </div>
      )}


{/* Pagination controls */}
<div className="pagination">
  {showbanner.length > bannersPerPage && (
    <>
      {currentPage > 1 && (
        <button onClick={() => paginate(currentPage - 1)}>
          Previous
        </button>
      )}
      {generatePaginationButtons()}
      {currentPage < Math.ceil(showbanner.length / bannersPerPage) && (
        <button onClick={() => paginate(currentPage + 1)}>
          Next
        </button>
      )}
    </>
  )}
</div>

{/* end */}
    </div>
    </>
    )
}
export default Blogchild;