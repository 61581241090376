import { Link } from 'react-router-dom'; // Import Redirect and Link components

const Add = ({ handleLogin, handleLogout, username, password, setEmail, setPassword, ToastContainer }) => {
    return (
        <>
            <div style={styles.container}>
                <input
                    style={styles.input}
                    type='text'
                    placeholder='Username'
                    value={username}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    style={styles.input}
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    style={styles.button}
                    onClick={handleLogin}
                >
                    Login
                </button>
                {/* <Link to="/login" style={styles.link} onClick={handleLogout}>Logout</Link> */}

                {/* Conditionally render AdminPanel based on login state */}
            </div>
        </>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column', // Align items vertically for mobile
        alignItems: 'center',
        marginLeft: '1rem',
    },
    input: {
        width: '100%', // Make input fields take full width
        maxWidth: '300px', // Limit maximum width for better readability on larger screens
        padding: '0.7rem',
        borderRadius: '5px',
        border: '1px solid #ccc',
        marginTop: '1rem', // Reduce top margin for better spacing
    },
    button: {
        width: '100%', // Make button take full width
        maxWidth: '100px', // Limit maximum width for better readability on larger screens
        padding: '0.6rem',
        borderRadius: '5px',
        backgroundColor: '#0467a0',
        color: '#fff',
        cursor: 'pointer',
        border: '1px solid #ccc',
        marginLeft: '-225px',
    },
    link: {
        textDecoration: 'none',
        color: '#007bff',
        marginTop: '1rem', // Reduce top margin for better spacing
    },
};

export default Add;
