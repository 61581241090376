import { useState } from "react";
import "./WhyChooseUsChild.scss";
import { FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from "react-icons/fa";
import { urlApi } from "../Context/url";
import { RxCross1 } from "react-icons/rx";
import BuildingImage from '../BottomNav/MUBuilding.jpg'

const WhyChooseUsChild = ({ data, data2 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [pop2, setPop2] = useState(false);
  const [pop, setPop] = useState(false);
  const [popdata, setPopdata] = useState([]);
  const [popheader, setPopheader] = useState([]);

  const [viewPop, setViewPop] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const dataHandler = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const popUpHandler = (img, header, text, body, created, index) => {
    setPopdata(body);
    setPopheader(header);
    setPop(true);
  }

  const closePopUp = () => {
    setPop(false);
  };

  const viewAllHandler = () => {
    setViewPop(true);
  };

  const closeViewPop = () => {
    setViewPop(false);
    setSelectedItemIndex(-1);
    setPop(false);
  };

  const viewPopUpHandler = (img, header, text, body, created, index) => {
    setPopdata(body);
    setPopheader(header);
    setPop2(true);
    setSelectedItemIndex(index);
    setPop(true);
  };

  const closePopUp2 = () => {
    setPop2(false);
  };

  const latestItems = data2
  .sort((a, b) => new Date(b.created) - new Date(a.created)) // Sort by date in descending order
  .slice(0, 2); // Take the first two items


  return (
    <>
      <div className="main-div-parent">
        {/* ... (existing code) */}
        <div className="child-div-child">
          <p className="head1">WHY CHOOSE US</p>
          {data.map((item, index) => (
            <div className="child-div-child" key={index}>
              <div className="child-div">
                <p onClick={() => dataHandler(index)}>
                  {item.name}{" "}
                  {activeIndex === index ? (
                    <FaRegArrowAltCircleDown className="downicon" />
                  ) : (
                    <FaRegArrowAltCircleUp className="downicon" />
                  )}
                </p>
                {activeIndex === index && (
                  <span>
                    {item.body}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        
        <div className="child-div-child">
          <div className="contflex">
            <p className="head">BLOG</p>
            <p className="head2" onClick={viewAllHandler}>VIEW ALL</p>x
          </div>
          {latestItems.map((data, index) => (
            <div className="blog-div" key={data.id}>
              <div>
                <img src={`${urlApi}/${data.img}`} alt="img" onClick={() => popUpHandler(data.img, data.header, data.text, data.body, data.created, index)} />
              </div>
              <div>
                <p className="ctstart">{data.header}</p>
                <p className="txt">{data.text}</p>
                <button onClick={() => popUpHandler(data.img, data.header, data.text, data.body, data.created, index)}>DETAILS</button>
                <p className="p30">30 Comments</p>
                <p className="datefloat">Posted on {data.created}</p>
              </div>
            </div>
          ))}
        </div>

        {/* ... (existing code) */}

      </div>

      {pop && (
        <div className="overlay" onClick={closePopUp}>
           {/* <img src={BuildingImage} alt="hi"  className="imginsidediv"/> */}
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closePopUp}><RxCross1 className="crossbtn" /></span>
            <span className="htext-keep-moving"><p className="colorpp">{popheader}</p></span>
            <p className="colorp" dangerouslySetInnerHTML={{ __html: popdata }}></p>
          </div>
        </div>
      )}

      {viewPop && (
        <>
          <div className="overlay" onClick={closeViewPop}>
          {/* <img src={BuildingImage} alt="hi"  className="imginsidediv"/> */}
            <div className="popup small-popup" onClick={(e) => e.stopPropagation()}>
              {data2.map((data, index) => (
                <div
                  className={`child-box small-box ${selectedItemIndex === index ? 'active' : ''}`}
                  key={index}
                >
                  <img
                    src={`${urlApi}/${data.img}`}
                    alt="img1"
                    onClick={() => viewPopUpHandler(data.img, data.header, data.text, data.body, data.created, index)}
                  />
                  <p>{data.header}</p>
                </div>
              ))}
              <span onClick={closeViewPop}><RxCross1 className="cross-btn" /></span>
            </div>
          </div>
        </>
      )}

{pop2 && (
        <div className="overlay" onClick={closePopUp2}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closePopUp2}><RxCross1 className="crossbtn" /></span>
            <span className="htext-keep-moving"><p>{popheader}</p></span>
            <p dangerouslySetInnerHTML={{ __html: popdata }}></p>
          </div>
        </div>
      )}
    </>
  );
};

export default WhyChooseUsChild;
