import { urlApi } from "../Context/url";
import "./Child.scss";
import { FaDownload } from 'react-icons/fa';

const Child = ({data}) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th className="sn">S.N</th>
            <th>Name</th>
            <th className="date">Date</th>
          </tr>
        </thead>
        {
            data.map((data, index) => {
                return <>
                 <tbody>
                <tr>
                    <td>{index+1}</td>
                    {/* <td><a href={`${urlApi}/${data.file}`} target="__blank" alt='file'>{data.name}</a></td> */}
                    <td>
                    <a href={`${urlApi}/${data.file}`} download={`${urlApi}/${data.file}`} alt='file' rel="noopener noreferrer" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', gap: '74%' }}>
                    <span>{data.name}</span>
                    <FaDownload />
                  </a>
                  </td>
                    <td>{data.updated_at}</td>
                </tr>
                </tbody>
                </>
            })
        }
       

      </table>
      <p className="pc">Downloads</p>
    </>
  );
};
export default Child;
