import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { urlApi } from "../../Context/url";
import Blogchild from "./blogchild";
import { getToken } from "../LocalStorageService";

const Blog = () => {
  const [formData, setFormData] = useState({
    img: null,
    header: "",
    text: "",
    body: "",
  });

  ///////////////////////////////////////////////////////////////////////////////
  // for put
  const [formData1, setFormData1] = useState({
    img1: null, // Change to null initially
    header1: "",
    text1: "",
    body1: "",
  });

  const [showbanner, setShowBanner] = useState([]);
  const [put, setPut] = useState([]);

  // for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const bannersPerPage = 6; // You can adjust this based on your preference
  const [clearEditor, setClearEditor] = useState(false); // Add this state
  const [clearImage, setClearImage] = useState(false); // Add this state for image field

  // for pagination
  // Calculate indexes for pagination
  const indexOfLastBanner = currentPage * bannersPerPage;
  const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
  const currentBanners = showbanner.slice(
    indexOfFirstBanner,
    indexOfLastBanner
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //
  // Add this function to generate pagination buttons dynamically
  const generatePaginationButtons = () => {
    const totalPages = Math.ceil(showbanner.length / bannersPerPage);
    const visiblePages = 18; // Number of visible pagination buttons

    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > visiblePages) {
      const half = Math.floor(visiblePages / 2);
      startPage = Math.max(currentPage - half, 1);
      endPage = Math.min(startPage + visiblePages - 1, totalPages);
      if (endPage - startPage + 1 < visiblePages) {
        startPage = endPage - visiblePages + 1;
      }
    }

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          style={{
            fontWeight: currentPage === i ? "bold" : "normal",
            color: currentPage === i ? "red" : "white",
          }}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  // put request
  const putHandler = (data) => {
    setPut(data);
    setFormData1(data);
    // putBanner(data.id);
  };

  const putBanner = async () => {
    const accessToken = getToken().access_token;
    try {
      // formData.append('text', text1);   // Append text value
      const formDatappend = new FormData();
      if (formData1.img1) {
        formDatappend.append("img", formData1.img1);
      }
      if (formData1.header1 && formData1.header1.trim() !== "") {
        formDatappend.append("header", formData1.header1);
      }
      if (formData1.text1 && formData1.text1.trim() !== "") {
        formDatappend.append("text", formData1.text1);
      }
      if (formData1.body1 && formData1.body1.trim() !== "") {
        formDatappend.append("body", formData1.body1);
      }

      const response = await fetch(`${urlApi}/blog/${put.id}/`, {
        method: "PUT",
        body: formDatappend,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log("Data saved successfully");
        toast.success("Saved!");
        // Clear the form fields after a successful submission
        setFormData1([]);
        // Update the state with the modified banner
        const updatedShowBanner = showbanner.map((item) =>
          item.id === put.id
            ? {
                ...item,
                img: formData1.img1,
                header: formData1.header1,
                text: formData1.text1,
                body: formData1.body1,
              }
            : item
        );

        setShowBanner(updatedShowBanner);
        setPut([]); // Clear the 'put' state
        await fetchBanners(); // Fetch banners after deleting to update the list
      } else {
        // const errorMessage = await response.text();
        // console.error('Error saving data:', errorMessage);
        // notifyError('Required fields not filled.', errorMessage);
        const errorMessage = await response.text();
        console.error("Error saving data:", errorMessage);
        toast.error("Required fields not filled.", errorMessage);
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data", error.message);
    }
  };

  // Delete handler
  const deleteHandler = (data) => {
    deleteBanner(data);
  };

  // for delete banner

  const deleteBanner = async (id) => {
    const accessToken = getToken().access_token;
    try {
      const response = await fetch(`${urlApi}/blog/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        console.log("Data deleted successfully");
        toast.success("Deleted!");
        await fetchBanners(); // Fetch banners after deleting to update the list
      } else {
        const errorMessage = await response.text();
        console.error("Error saving data:", errorMessage);
        toast.error("Required fields not filled.", errorMessage);
      }
    } catch (error) {
      console.error("Error deleting data:", error.message);
      toast.success("Error deleting data", error.message);
    }
  };

  // for get request
  const fetchBanners = async () => {
    try {
      const response = await fetch(`${urlApi}/blog/`);
      if (response.ok) {
        const data = await response.json();
        setShowBanner(data);
      } else {
        console.log("Error fetching banners");
      }
    } catch (error) {
      console.log("Error fetching banners:", error.message);
    }
  };

  useEffect(() => {
    const fetchAndSetBanners = async () => {
      await fetchBanners();
      setCurrentPage(1);
    };
    fetchAndSetBanners();
  }, []);

  const handleContentChange1 = (e) => {
    if (e.target.name === "img1") {
      setFormData1({ ...formData1, [e.target.name]: e.target.files[0] });
    } else {
      setFormData1({ ...formData1, [e.target.name]: e.target.value });
    }
  };
  ///////////////////////////////////////////////////////////////////

  const handleContentChange = (e, property) => {
    if (e.target.name === "img") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else if (property) {
      setFormData({ ...formData, [property]: e.target.value });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const accessToken = localStorage.getItem('accessToken');
    const accessToken = getToken().access_token;
    if (accessToken) {
      try {
        const formDataObject = new FormData();
        formDataObject.append("img", formData.img);
        formDataObject.append("header", formData.header);
        formDataObject.append("text", formData.text);
        formDataObject.append("body", formData.body);

        const response = await fetch(`${urlApi}/blog/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formDataObject,
        });

        if (!response.ok) {
          throw new Error("Network response is not ok");
        }
        // Handle successful response
        toast.success("Data added successfully");
        // Clear specific fields in formData
        setFormData({
          ...formData,
          img: null,
          header: "",
          text: "",
          // Assuming the name of the 'body' field is 'body'
          body: "",
        });
        // Clear the QuillEditor content
        setClearEditor(true);
        // Clear the image field
        setClearImage(true);
        await fetchBanners();
      } catch (error) {
        console.log("Error adding data", error);
        toast.error("Error adding data");
      } finally {
        // Reset clearImage state after clearing the image field
        setClearImage(false);
        setClearEditor(false);
      }
    } else {
      console.log("Access token is missing");
      toast.error("Authentication credentials not provided");
    }
  };

  return (
    <Blogchild
      handleSubmit={handleSubmit}
      handleContentChange={handleContentChange}
      formData={formData}
      currentBanners={currentBanners}
      handleContentChange1={handleContentChange1}
      putHandler={putHandler}
      deleteHandler={deleteHandler}
      put={put}
      putBanner={putBanner}
      formData1={formData1}
      showbanner={showbanner}
      currentPage={currentPage}
      bannersPerPage={bannersPerPage}
      generatePaginationButtons={generatePaginationButtons}
      paginate={paginate}
      clearEditor={clearEditor}
      clearImage={clearImage}
    />
  );
};

export default Blog;
