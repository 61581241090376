// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-height {
  width: 400px !important;
}
.article-height img {
  width: 15%;
  height: 15%;
  padding-top: 5px;
  object-fit: cover;
}

#container-main-g {
  display: flex;
  flex-direction: column;
}

#fiille {
  width: 25%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/Wetefe/WetefeChild.scss"],"names":[],"mappings":"AACA;EACI,uBAAA;AAAJ;AACI;EACI,UAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;AACR;;AAGA;EACI,aAAA;EACA,sBAAA;AAAJ;;AAIA;EACI,UAAA;AADJ","sourcesContent":["\n.article-height {\n    width: 400px !important;\n    img {\n        width: 15%;\n        height: 15%;\n        padding-top: 5px;\n        object-fit: cover;\n    }\n}\n\n#container-main-g {\n    display: flex;\n    flex-direction: column;\n}\n\n\n#fiille {\n    width: 25%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
