// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-snow .ql-editor img {
  max-width: 90%;
  max-height: 50%;
  object-fit: cover;
  line-height: 1.6rem;
}

.ql-toolbar.ql-snow .ql-picker-label {
  padding-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/Notice/Editor.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".ql-snow .ql-editor img {\n    max-width: 90%;\n    max-height: 50%;\n    object-fit: cover;\n    line-height: 1.6rem;\n}\n\n.ql-toolbar.ql-snow .ql-picker-label { \n    padding-top: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
