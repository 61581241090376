import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Editor.scss';

const QuillEditor = ({ onContentChange, clearEditor }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    // clear the editor content when the clearContent prop changes
    if(clearEditor) {
      setValue('');
    }
  }, [clearEditor])


  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ align: [] }], // Include text alignment options
    ],
  };

  const formats = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background',
    'script', 'list', 'bullet',
    'link', 'image', 'video', 'align',
  ];

  const handleContentChange = (content) => {
    setValue(content);
    // Pass the content directly to the parent component
    onContentChange({ target: { name: 'body', value: content } });
  };

  return (
    <div className="quill-editor-container">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleContentChange}
        modules={modules}
        formats={formats}
        placeholder="Start writing..."
        style={{height: '210px', objectFit: 'cover', marginBottom: '60px'}}

      />
    </div>
  );
};

export default QuillEditor;
