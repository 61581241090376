import React, { useState, useEffect } from 'react';
import QuillEditor from './Editor';
import { ToastContainer, toast } from 'react-toastify';
import './Events.scss';
import { urlApi } from '../../Context/url';
import { getToken } from '../LocalStorageService';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";
import PUTEDITOR from './PutEditor';

const Notices = () => {
  const [formData, setFormData] = useState({
    name: '',
    img: null, // Change to null initially
    body: '',
  });


  ///////////////////////////////////////////////////////////////////////////////
   // for put
   const [formData1, setFormData1] = useState({
    name1: '',
    img1: null, // Change to null initially
    body1: '',
  });
  


   const [showbanner, setShowBanner] = useState([])
   const [put, setPut] = useState([])
   
   // for pagination
   const [currentPage, setCurrentPage] = useState(1);
   const bannersPerPage = 6; // You can adjust this based on your preference
   const [clearEditor, setClearEditor] = useState(false); // Add this state
   const [clearImage, setClearImage] = useState(false); // Add this state for image field


   
 // for pagination
 // Calculate indexes for pagination
 const indexOfLastBanner = currentPage * bannersPerPage;
 const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
 const currentBanners = showbanner.slice(indexOfFirstBanner, indexOfLastBanner);

 // Change page
 const paginate = (pageNumber) => setCurrentPage(pageNumber);


 //  
// Add this function to generate pagination buttons dynamically
const generatePaginationButtons = () => {
 const totalPages = Math.ceil(showbanner.length / bannersPerPage);
 const visiblePages = 18; // Number of visible pagination buttons

 let startPage = 1;
 let endPage = totalPages;

 if (totalPages > visiblePages) {
   const half = Math.floor(visiblePages / 2);
   startPage = Math.max(currentPage - half, 1);
   endPage = Math.min(startPage + visiblePages - 1, totalPages);
   if (endPage - startPage + 1 < visiblePages) {
     startPage = endPage - visiblePages + 1;
   }
 }

 const buttons = [];
 for (let i = startPage; i <= endPage; i++) {
   buttons.push(
     <button
       key={i}
       onClick={() => paginate(i)}
       style={{ fontWeight: currentPage === i ? 'bold' : 'normal', color: currentPage === i ? 'red' : 'white', }}
     >
       {i}
     </button>
   );
 }

 return buttons;
};


 // put request
 const putHandler = (data) => {
   setPut(data);
   setFormData1(data);

   // putBanner(data.id);
 }


 const putBanner = async () => {
   const accessToken = getToken().access_token
   try {
     // formData.append('text', text1);   // Append text value
      const formDatappend = new FormData();
      if(formData1.img1) {
        formDatappend.append('img', formData1.img1)
      }
      if (formData1.name1 && formData1.name1.trim() !== '') {
        formDatappend.append('name', formData1.name1);
      }
      if (formData1.body1 && formData1.body1.trim() !== '') {
        formDatappend.append('body', formData1.body1);
      }


     const response = await fetch(`${urlApi}/notice/${put.id}/`, {
       method: 'PUT',
       body: formDatappend,
       headers: {
         'Authorization': `Bearer ${accessToken}`,
     },
     });

     if (response.ok) {
       console.log('Data saved successfully');
       toast.success('Saved!');
        // Clear the form fields after a successful submission
        setFormData1([]);
        // Update the state with the modified banner
        const updatedShowBanner = showbanner.map(item =>
         item.id === put.id ? { ...item, name: formData1.name1, img: formData1.img1, body: formData1.body1 } : item
         );
         
         setShowBanner(updatedShowBanner);
         setPut([]); // Clear the 'put' state
         await fetchBanners(); // Fetch banners after deleting to update the list

     } else {
       // const errorMessage = await response.text();
       // console.error('Error saving data:', errorMessage);
       // notifyError('Required fields not filled.', errorMessage);
       const errorMessage = await response.text();
       console.error('Error saving data:', errorMessage);
       toast.error('Required fields not filled.', errorMessage);
     }
   } catch (error) {
     console.error('Error saving data:', error.message);
     toast.error('Error saving data', error.message);
   }
       
     }



 // Delete handler
 const deleteHandler = (data) => {
   deleteBanner(data);
 }

 // for delete banner

 const deleteBanner = async (id) => {
   const accessToken = getToken().access_token
   try {
     const response = await fetch(`${urlApi}/notice/${id}/`, {
       method: 'DELETE',
       headers: {
         'Authorization': `Bearer ${accessToken}`,
     },
     });
     if (response.ok) {
       console.log('Data deleted successfully');
       toast.success('Deleted!');
       fetchBanners(); // Fetch banners after deleting to update the list
     } else {
       const errorMessage = await response.text();
       console.error('Error saving data:', errorMessage);
       toast.error('Required fields not filled.', errorMessage);
     }
   } catch (error) {
     console.error('Error deleting data:', error.message);
     toast.success('Error deleting data', error.message);
   }
       
     }
   

 // for get request
 const fetchBanners = async () => {
   try {
     const response = await fetch(`${urlApi}/notice/`);
     if(response.ok) {
       const data = await response.json();
       setShowBanner(data);
       
       
     } else {
       console.log('Error fetching banners')
     }
   } catch(error) {
     console.log('Error fetching banners:', error.message);
   }
 }

 useEffect(() => {
   const fetchAndSetBanners = async () => {
     await fetchBanners();
     setCurrentPage(1);
   };
   fetchAndSetBanners();
 }, []);


 const handleContentChange1 = (e) => {
  if (e.target.name === 'img1') {
    setFormData1({ ...formData1, [e.target.name]: e.target.files[0] });
  } else {
    setFormData1({ ...formData1, [e.target.name]: e.target.value });
  }
};
///////////////////////////////////////////////////////////////////




  const handleContentChange = (e) => {
    if (e.target.name === 'img') {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const accessToken = localStorage.getItem('accessToken');
    const accessToken = getToken().access_token

    try {
      const formDataObject = new FormData();
      formDataObject.append('name', formData.name);
      formDataObject.append('img', formData.img);
      formDataObject.append('body', formData.body);

      const response = await fetch(`${urlApi}/notice/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
      },
        body: formDataObject,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle successful response
      toast.success('Data added successfully');
      // Clear the form fields after a successful submission
      setFormData({
        name: '',
        img: null,
        body: '',
      });
      // Clear the QuillEditor content
      setClearEditor(true);
       // Clear the image field
      setClearImage(true);
      await fetchBanners();
    } catch (error) {
      console.error('Error adding data:', error);
      // Handle error
      toast.error('Error adding data');
    } finally {
      // Reset clearImage state after clearing the image field
      setClearImage(false);
      setClearEditor(false);
    }
  };

  return (
    <div className="form-container">
      <br />
      <div>
        <div className="main-conatiner">
          <div className="child-container">
            <textarea
              type="text"
              className="form-input"
              placeholder="Header: No more than 30 characters only"
              name="name"
              value={formData.name}
              onChange={handleContentChange}
            />
          </div>
          <div className="child-container">
            <input
              type="file"
              className="form-input form-file-input"
              name="img"
              onChange={handleContentChange}
              key={clearImage} // Add a key to the input to trigger re-render when clearImage changes
            />
          </div>
        </div>
        <QuillEditor onContentChange={handleContentChange} clearContent={clearEditor}/>
      </div>
      <div>
        <button className="form-button" onClick={handleSubmit}>
          Save Content
        </button>
        <ToastContainer />
      </div>
      {/* start */}
  <article className='article-height'>
      {currentBanners.map((data, index)=> <div className='main-content'>
        <div className='child-main-container'>
          <div className='cont1'>
          <span>{index + 1}. </span>
          <span>{data.name}</span> 
          </div>
          <div className='cont2'>
          <FaEye /> <FaRegEdit onClick={()=> putHandler(data)}/> <RiDeleteBin5Fill onClick={()=> deleteHandler(data.id)}/>
          </div>
          </div>
      </div>)}
      </article>
      {put.id && (
        <div className='main-conatiner' id='main'>
        <div className='child-container' id='child'>
      <textarea
        type="text"
        name='name1'
        value={formData1.name1 || put.name}
        onChange={handleContentChange1}
        className="form-input"
        placeholder='Header: No more than 30 characters only'
      />
      </div>
    <div className='child-container' id='child'>
      <input
        type="file"
        name='img1'
        onChange={handleContentChange1}
        className="form-input form-file-input"
        required
      />
      </div>
      <div>
      </div>
      <div className='child-container' id='child'>
       <PUTEDITOR onContentChange={handleContentChange1}
        theme="snow"
        name='body1'
        value1={formData1.body1 || put.body}
        placeholder="Start writing..."
        style={{height: '210px', objectFit: 'cover', marginBottom: '60px'}}
       />
      </div>
     
      <button  id="bnbtn" onClick={putBanner}>
        Save Content
      </button>
      <ToastContainer />
    
    </div>
      )}


{/* Pagination controls */}
<div className="pagination">
  {showbanner.length > bannersPerPage && (
    <>
      {currentPage > 1 && (
        <button onClick={() => paginate(currentPage - 1)}>
          Previous
        </button>
      )}
      {generatePaginationButtons()}
      {currentPage < Math.ceil(showbanner.length / bannersPerPage) && (
        <button onClick={() => paginate(currentPage + 1)}>
          Next
        </button>
      )}
    </>
  )}
</div>

{/* end */}
    </div>
  );
};

export default Notices;
