import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Editor.scss';

const Editor2 = ({ onContentChange, clearContent, value1}) => {
  // const [value, setValue] = useState('');
  const [value, setValue] = useState(value1 || '');

  // useEffect(() => {
  //   // clear the editor content when the clearContent prop changes
  //   if(clearContent) {
  //     setValue('');
  //   }
  // }, [clearContent])

  // useEffect(() => {
  //   setValue(value1 || '');
  // }, [value1]);
  
  useEffect(() => {
    // clear the editor content when the clearContent prop changes
    if (clearContent) {
      setValue('');
    } else {
      setValue(value1 || '');
    }
  }, [clearContent, value1]);
  

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ align: [] }], // Include text alignment options
    ],
  };

  const formats = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background',
    'script', 'list', 'bullet',
    'link', 'image', 'video', 'align',
  ];

  const handleContentChange = (content) => {
    setValue(content);
    // Pass the content directly to the parent component
    onContentChange({ target: { name: 'body1', value: content } });
  };

  return (
    <div className="quill-editor-container">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleContentChange}
        modules={modules}
        formats={formats}
        placeholder="Write something amazing..."
        style={{height: '210px', objectFit: 'cover', marginBottom: '60px'}}

      />
    </div>
  );
};

export default Editor2;
