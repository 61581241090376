import { useState, useEffect } from 'react';
import { toast} from 'react-toastify';
import WetefeChild from './WetefeChild';
import { urlApi } from '../../Context/url';
import { getToken } from '../LocalStorageService';


const Wetefe = () => {
    const [formData, setFormData] = useState({
        img: null,
    })


    // start 
    // for put
    const [img1, setImg1] = useState(null);

    const [showbanner, setShowBanner] = useState([])
    const [put, setPut] = useState([])
    
    // for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const bannersPerPage = 6; // You can adjust this based on your preference

    
  // for pagination
  // Calculate indexes for pagination
  const indexOfLastBanner = currentPage * bannersPerPage;
  const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
  const currentBanners = showbanner.slice(indexOfFirstBanner, indexOfLastBanner);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  //  
// Add this function to generate pagination buttons dynamically
const generatePaginationButtons = () => {
  const totalPages = Math.ceil(showbanner.length / bannersPerPage);
  const visiblePages = 18; // Number of visible pagination buttons

  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > visiblePages) {
    const half = Math.floor(visiblePages / 2);
    startPage = Math.max(currentPage - half, 1);
    endPage = Math.min(startPage + visiblePages - 1, totalPages);
    if (endPage - startPage + 1 < visiblePages) {
      startPage = endPage - visiblePages + 1;
    }
  }

  const buttons = [];
  for (let i = startPage; i <= endPage; i++) {
    buttons.push(
      <button
        key={i}
        onClick={() => paginate(i)}
        style={{ fontWeight: currentPage === i ? 'bold' : 'normal', color: currentPage === i ? 'red' : 'white', }}
      >
        {i}
      </button>
    );
  }

  return buttons;
};






  // put request
  const putHandler = (data) => {
    setPut(data);
    // putBanner(data.id);
  }

  const handleContentChange1 = (e) => {
    setImg1(e.target.files[0]);
  };

  const putBanner = async () => {
    const accessToken = getToken().access_token
    try {
      const formData = new FormData();
     
      if(img1) {
        formData.append('img', img1)
      }
      
      const response = await fetch(`${urlApi}/onebanner/${put.id}/`, {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
      },
      });

      if (response.ok) {
        console.log('Data saved successfully');
        toast.success('Saved!');
         // Clear the form fields after a successful submission
         setImg1(null);
         // Update the state with the modified banner
         const updatedShowBanner = showbanner.map(item =>
          item.id === put.id ? { ...item, img: img1 } : item
          );
          
          setShowBanner(updatedShowBanner);
          setPut([]); // Clear the 'put' state
          await fetchBanners(); // Fetch banners after deleting to update the list

      } else {
        // const errorMessage = await response.text();
        // console.error('Error saving data:', errorMessage);
        // notifyError('Required fields not filled.', errorMessage);
        const errorMessage = await response.text();
        console.error('Error saving data:', errorMessage);
        toast.error('Required fields not filled.', errorMessage);
      }
    } catch (error) {
      console.error('Error saving data:', error.message);
      toString.error('Error saving data', error.message);
    }
        
      }



  // Delete handler
  const deleteHandler = (data) => {
    deleteBanner(data);
  }

  // for delete banner

  const deleteBanner = async (id) => {
    const accessToken = getToken().access_token
    try {
      const response = await fetch(`${urlApi}/onebanner/${id}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
      },
      });
      if (response.ok) {
        console.log('Data deleted successfully');
        toast.success('Deleted!');
        fetchBanners(); // Fetch banners after deleting to update the list
      } else {
        const errorMessage = await response.text();
        console.error('Error saving data:', errorMessage);
        toast.error('Required fields not filled.', errorMessage);
      }
    } catch (error) {
      console.error('Error deleting data:', error.message);
      toast.error('Error deleting data', error.message);
    }
        
      }
    

  // for get request
  const fetchBanners = async () => {
    try {
      const response = await fetch(`${urlApi}/onebanner/`);
      if(response.ok) {
        const data = await response.json();
        setShowBanner(data);
        
        
      } else {
        console.log('Error fetching banners')
      }
    } catch(error) {
      console.log('Error fetching banners:', error.message);
    }
  }

  useEffect(() => {
    const fetchAndSetBanners = async () => {
      await fetchBanners();
      setCurrentPage(1);
    };
    fetchAndSetBanners();
  }, []);



    //end



    const handleContentChange = (e) => {
        if(e.target.name === 'img') {
            setFormData({...formData, [e.target.name]: e.target.files[0]})
        }  
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        // const accessToken = localStorage.getItem('accessToken');
        const accessToken = getToken().access_token
        try {
            const formObjectData = new FormData();
            formObjectData.append('img', formData.img);
            
            const response = await fetch(`${urlApi}/onebanner/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formObjectData
            })
            if(!response.ok) {
                throw new Error('Network response is not ok');
            }
            toast.success('Data added successfully');
            setFormData({
                ...formData, 
                img: null,
            })
            await fetchBanners();

        } catch(error) {
            console.log('Error adding data', error)
            toast.error('Error adding data')
        } 
    }


    return (
      <WetefeChild handleContentChange={handleContentChange} handleSubmit={handleSubmit} formData={formData}
      currentBanners={currentBanners}
      handleContentChange1={handleContentChange1}
      putHandler={putHandler}
      deleteHandler={deleteHandler}
      put={put}
      putBanner={putBanner}
      img = {img1}
      showbanner={showbanner}
      currentPage={currentPage}
      bannersPerPage={bannersPerPage}
      generatePaginationButtons={generatePaginationButtons}
      paginate={paginate}
      />
    )
}
export default Wetefe;