// UniversityCampusesItems.jsx

import './UniversityCampusesItems.scss';
import { urlApi } from '../Context/url';
import { useState, useCallback, useEffect } from 'react';
import { TfiShiftLeftAlt, TfiShiftRightAlt } from "react-icons/tfi";

const UniversityCampusesItems = ({ udata }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const itemsPerPage = screenWidth <= 480 || screenWidth <= 320 ? 1 : 3;
  // const itemsPerPage = screenWidth <= 480 || screenWidth <= 320 ? 1 : (screenWidth <= 1024 || screenWidth >= 601 ? 2 : 3);
  // Define itemsPerPage based on screenWidth

  // let itemsPerPage = 3; // Default value
  // if (screenWidth <= 480) {
  //   itemsPerPage = 1;
  // } else if (screenWidth >= 481 ) {
  //   itemsPerPage = 3;
  // } else if (screenWidth <= 1024 || screenWidth <= 601) {
  //   itemsPerPage = 3;
  // }

  const itemsPerPage =
  screenWidth <= 600
    ? 1
    : screenWidth <= 800
    ? 2
    : 3;


  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = udata.length;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedItems =
    totalItems > itemsPerPage
      ? udata.slice(startIndex, endIndex)
      : udata;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
  }, [totalPages]);
  // const handleNextPage = useCallback(() => {
  //   setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
  // }, [totalPages]);


  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  if (!udata || udata.length === 0) {
    return (
      <>
        <h1 className='h1text'>Campuses Around Nepal</h1>
        <p>Not available</p>
      </>
    );
  }

  return (
    <div className="imgcontainer">
      <p className='abtclass'>OUR INSTITUTIONS</p>
      {displayedItems.map((data) => (
        <div className="imgitem" key={data.id}>
          <a href={data.link} target="_blank" rel="noopener noreferrer">
            <img src={`${urlApi}/${data.img}`} alt="img" />
          </a>
          <p>{data.heading} <br />{data.address}</p>
        </div>
      ))}
      <TfiShiftLeftAlt className='right-shift-data' onClick={handlePrevPage} />
      <TfiShiftRightAlt className='left-shift-data' onClick={handleNextPage} />
    </div>
  );
};

export default UniversityCampusesItems;
