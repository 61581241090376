import { urlApi } from '../Context/url';
import { useState, useCallback, useEffect } from 'react';
import { TfiShiftLeftAlt, TfiShiftRightAlt } from "react-icons/tfi";
import { RxCross1 } from "react-icons/rx";
import './EventChild.scss';
import { Helmet } from 'react-helmet';



const EventChild = ({ event }) => {
  const [pop, setPop] = useState(false);
  const [pop2, setPop2] = useState(false);
  const [popdata, setPopdata] = useState([]);
  const [vpopdata, setvPopdata] = useState([]);
  const [header, setHeader] = useState([]);
  const [vheader, setvHeader] = useState([]);
  const [viewPop, setViewPop] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [img, setImg] = useState([]);
  const [data, setData] = useState([]);

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = event.length;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedItems =
    totalItems > itemsPerPage
      ? event.slice(startIndex, endIndex)
      : event;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
  }, [totalPages]);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  const popUpHandler = (body, name, img) => {
    setImg(img)
    
    setPopdata(body);
    setHeader(name);
    setPop(true);
    setViewPop(false);
    
  };

  const closePopUp = () => {
    setPop(false);
  };

  const viewAll = () => {
    setViewPop(true);
    setPop(false);
    
  };

  const closeViewPop = () => {
    setViewPop(false);
    setSelectedItemIndex(-1);
    setPop(false);
  };

  const viewPopUpHandler = (body, name, index) => {
    setvPopdata(body);
    setvHeader(name);
    setPop2(true);
    setSelectedItemIndex(index);
  };
  const closePopUp2 = () => {
    setPop2(false);
  };

  
  const shareToFacebook = () => {
    // Assuming `img`, `header`, and `popdata` are set correctly by popUpHandler
    const imageUrl = `${urlApi}/${img}`;
    const title = data; // Use the header as the title
    const description = data; // Use the popdata as the description

    // Update meta tags directly in the document head
    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute('content', title);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute('content', description);
    }

    const ogImageTag = document.querySelector('meta[property="og:image"]');
    if (ogImageTag) {
      ogImageTag.setAttribute('content', imageUrl);
    }

    // Open the Facebook sharing dialog
    const url = encodeURIComponent(window.location.href);
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    window.open(shareUrl, '_blank');
  };

  // useEffect to cleanup meta tags when component unmounts
  useEffect(() => {
    return () => {
      // Reset meta tags when component unmounts
      const ogTitleTag = document.querySelector('meta[property="og:title"]');
      if (ogTitleTag) {
        ogTitleTag.setAttribute('body', '');
      }

      const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
      if (ogDescriptionTag) {
        ogDescriptionTag.setAttribute('body', '');
      }

      const ogImageTag = document.querySelector('meta[property="og:image"]');
      if (ogImageTag) {
        ogImageTag.setAttribute('body', '');
      }
    };
  }, []);



  if (!event || event.length === 0) {
    return (
      <>
        <h1 className='h1text'>Event</h1>
        <p>Not available</p>
      </>
    );
  }

  return (
    <>
      <div className="parent-box">
        <div className="child-box1" id='main1'>
          <h1>News & Research Higlights</h1>
          <button onClick={viewAll}>View All</button>
        </div>

        {displayedItems.map((data, index) => (
          <div className="child-box" key={index}>
            {index === 0 && <TfiShiftLeftAlt className='right-shift-data1' onClick={handlePrevPage} />}
            <img src={`${urlApi}/${data.img}`} alt="img1" onClick={() => popUpHandler(data.body, data.name, data.img)} />
            <p>{data.name}</p>
            {index === displayedItems.length - 1 && <TfiShiftRightAlt className='left-shift-data1' onClick={handleNextPage} />}
          </div>
        ))}
      </div>

      {pop && (
        <div className="overlay" onClick={closePopUp}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closePopUp}><RxCross1 className="crossbtn" /></span>
            <span className="htext-keep-moving"><p>{header}</p></span>
            <p dangerouslySetInnerHTML={{ __html: popdata }}></p>
            <button onClick={shareToFacebook}>Share on Facebook</button>
          </div>
        </div>
      )}
      {viewPop && (
        <>
        <div className="overlay" onClick={closeViewPop}>
          <div className="popup small-popup" onClick={(e) => e.stopPropagation()}>
            {event.map((data, index) => (
              <div
              className={`child-box small-box ${selectedItemIndex === index ? 'active' : ''}`}
              key={index}
              >
                <img
                className='bbid'
                  src={`${urlApi}/${data.img}`}
                  alt="img1"
                  onClick={() => viewPopUpHandler(data.body, data.name, index)}
                />
                <p>{data.name}</p>
              </div>
            ))}
           
          <span onClick={closeViewPop}><RxCross1 className="cross-btn" /></span>
          </div>
        </div>
       </>
      )}
      {pop2 && (
        <div className="overlay" onClick={closePopUp2}>
            {/* <img src={BuildingImage} alt="hi"  className="imginsidediv"/> */}
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closePopUp2}><RxCross1 className="crossbtn" /></span>
            <span className="htext-keep-moving"><p>{vheader}</p></span>
            <p dangerouslySetInnerHTML={{ __html: vpopdata }}></p>
          </div>
        </div>
      )}

    </>
  );
};

export default EventChild;