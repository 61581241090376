import { useState, useEffect } from "react";
import AcaddmicChild from "./AcaddmicChild";
import { toast} from 'react-toastify';
import { urlApi } from "../../Context/url";
import { getToken } from "../LocalStorageService";

const Academic = () => {
    const [form, setForm] = useState({
        img: null,
    })


    ///////////////////////////////////////////////////////////////////////////////
    // for put
    const [formData1, setFormData1] = useState({
        img1: null, // To store the selected image file
    });

    const [showbanner, setShowBanner] = useState([]);
    const [put, setPut] = useState([]);

    // for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const bannersPerPage = 6; // You can adjust this based on your preference
    const [clearImage, setClearImage] = useState(false); // Add this state for image field


    // for pagination
    // Calculate indexes for pagination
    const indexOfLastBanner = currentPage * bannersPerPage;
    const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
    const currentBanners = showbanner.slice(
        indexOfFirstBanner,
        indexOfLastBanner
    );

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //
    // Add this function to generate pagination buttons dynamically
    const generatePaginationButtons = () => {
        const totalPages = Math.ceil(showbanner.length / bannersPerPage);
        const visiblePages = 18; // Number of visible pagination buttons

        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > visiblePages) {
            const half = Math.floor(visiblePages / 2);
            startPage = Math.max(currentPage - half, 1);
            endPage = Math.min(startPage + visiblePages - 1, totalPages);
            if (endPage - startPage + 1 < visiblePages) {
                startPage = endPage - visiblePages + 1;
            }
        }

        const buttons = [];
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => paginate(i)}
                    style={{
                        fontWeight: currentPage === i ? "bold" : "normal",
                        color: currentPage === i ? "red" : "white",
                    }}
                >
                    {i}
                </button>
            );
        }

        return buttons;
    };

    // put request
    const putHandler = (data) => {
        setPut(data);
        setFormData1(data);
        // putBanner(data.id);
    };

    const putBanner = async () => {
        const accessToken = getToken().access_token;
        try {
            const formDataObject = new FormData();
            if (formData1.img1) {
                formDataObject.append('img', formData1.img1);
            }
    
            const response = await fetch(`${urlApi}/academicpartner/${put.id}/`, {
                method: "PUT",
                body: formDataObject,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            if (response.ok) {
                console.log("Data saved successfully");
                toast.success("Saved!");
                // Clear the form fields after a successful submission
                setFormData1({
                    img1: null,
                });
    
                // Update the state with the modified banner
                const updatedShowBanner = showbanner.map((item) =>
                    item.id === put.id
                        ? {
                            ...item,
                            img: formData1.img1,
                        }
                        : item
                );
    
                setShowBanner(updatedShowBanner);
                setPut([]); // Clear the 'put' state
                await fetchBanners(); // Fetch banners after updating to update the list
            } else {
                const errorMessage = await response.text();
                console.error("Error saving data:", errorMessage);
                toast.error("Required fields not filled.", errorMessage);
            }
        } catch (error) {
            console.error("Error saving data:", error.message);
            toast.error("Error saving data", error.message);
        }
    };
    
    

    // Delete handler
    const deleteHandler = (data) => {
        deleteBanner(data);
    };

    // for delete banner

    const deleteBanner = async (id) => {
        const accessToken = getToken().access_token;
        try {
            const response = await fetch(`${urlApi}/academicpartner/${id}/`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                console.log("Data deleted successfully");
                toast.success("Deleted!");
                fetchBanners(); // Fetch banners after deleting to update the list
            } else {
                const errorMessage = await response.text();
                console.error("Error saving data:", errorMessage);
                toast.error("Required fields not filled.", errorMessage);
            }
        } catch (error) {
            console.error("Error deleting data:", error.message);
            toast.error("Error deleting data", error.message);
        }
    };

    // for get request
    const fetchBanners = async () => {
        try {
            const response = await fetch(`${urlApi}/academicpartner/`);
            if (response.ok) {
                const data = await response.json();
                setShowBanner(data);
                
            } else {
                console.log("Error fetching banners");
            }
        } catch (error) {
            console.log("Error fetching banners:", error.message);
        }
    };

    useEffect(() => {
        const fetchAndSetBanners = async () => {
            await fetchBanners();
            setCurrentPage(1);
        };
        fetchAndSetBanners();
    }, []);

    const handleContentChange1 = (e) => {
        if(e.target.name === 'img1') {
            setFormData1({...formData1, [e.target.name]: e.target.files[0]});
        }
    }

    ///////////////////////////////////////////////////////////////////




    const handleChange = (e) => {
        if(e.target.name === 'img') {
            setForm({...form, [e.target.name]: e.target.files[0]});
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        //const accessToken = localStorage.getItem('accessToken');
        const accessToken = getToken().access_token
        try {
            const formData = new FormData();
            formData.append('img', form.img);

            const response = await fetch(`${urlApi}/academicpartner/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData
            })
            if(!response.ok) {
                throw new Error('Network response is not ok');
            }

            toast.success('Data added sucessfully');
            setClearImage(true);
            fetchBanners();

        } catch(error) {
            console.log('Error Adding Data', error)
            toast.error('Error adding data')
        }finally {
            // Reset clearImage state after clearing the image field
            setClearImage(false);
          }
    }



    return <AcaddmicChild handleChange={handleChange} handleSubmit={handleSubmit} form={form}
    currentBanners={currentBanners}
    handleContentChange1={handleContentChange1}
    putHandler={putHandler}
    deleteHandler={deleteHandler}
    put={put}
    putBanner={putBanner}
    formData1={formData1}
    showbanner={showbanner}
    currentPage={currentPage}
    bannersPerPage={bannersPerPage}
    generatePaginationButtons={generatePaginationButtons}
    paginate={paginate}
    clearImage={clearImage}/>
}
export default Academic;