// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mtcont {
  flex-direction: column;
  padding-top: 1.2rem;
  width: 400px;
}

#article-height {
  width: 400px !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/AcademicPartner/AcademicPartner.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,mBAAA;EACA,YAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":["#mtcont {\n    flex-direction: column;\n    padding-top: 1.2rem;\n    width: 400px;\n}\n\n#article-height {\n    width: 400px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
