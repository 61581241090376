import { ToastContainer } from 'react-toastify';
import './wcuchild.scss';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";

const wcuchild = ({formData, handleSubmit, handleChange, currentBanners, handleContentChange1, putHandler, deleteHandler, put, putBanner, formData1, showbanner, currentPage, bannersPerPage, generatePaginationButtons, paginate}) => {
    return (
      <>
      <br/>
        <div className='main-conatiner'>
        <div className='child-container'>
      <textarea
        type="text"
        className="form-input"
        value={formData.name}
        onChange={(e) => handleChange(e, 'name')}
        placeholder='Header: No more than 50 characters only'
        id='textareachild'
      />
      </div>
      <div className='child-container'>
      <textarea
        type="text"
        className="form-input"
        id='textareabox'
        value={formData.body}
        onChange={(e) => handleChange(e, 'body')}
        placeholder='Long Header: No more than 200 characters only'
      />
    </div>
      <div className='btncontainer'>
      <button onClick={handleSubmit} id="bnbtn">
        Save Content
      </button>
      <ToastContainer />
    </div>
    </div>
    
    {/* start */}
    <div className='article-main'>
    <article className='child-container'>
        {currentBanners.map((data, index)=> <div className='main-content'>
          <div className='child-main-container' id='childmainctn'>
            <div className='cont1'>
            <span>{index + 1}. </span>
            <span>{data.name}</span> 
            </div>
            <div className='cont2'>
            <FaEye /> <FaRegEdit onClick={()=> putHandler(data)}/> <RiDeleteBin5Fill onClick={()=> deleteHandler(data.id)}/>
            </div>
            </div>
        </div>)}
      </article>
    <article className='child-container'>
      {put.id && (
        <div className='main-conatiner' id='main'>
        <div className='child-container' id='child'>
      <textarea
        type="text"
        name='name1'
        value={formData1.name1 || put.name}
        onChange={handleContentChange1}
        className="form-input"
        placeholder='Header: No more than 30 characters only'
      />
      </div>
      <div className='child-container' id='child'>
      <textarea
        type="text"
        name='body1'
        value={formData1.body1 || put.body}
        onChange={handleContentChange1}
        className="form-input"
        placeholder='Header: No more than 30 characters only'
      />
      </div>
    
      <button  id="bnbtn" onClick={putBanner}>
        Save Content
      </button>
      <ToastContainer />
    
    </div>
      )}
    </article>
    {/* Pagination controls */}
    <div className="pagination">
      {showbanner.length > bannersPerPage && (
        <>
          {currentPage > 1 && (
            <button onClick={() => paginate(currentPage - 1)}>
              Previous
            </button>
          )}
          {generatePaginationButtons()}
          {currentPage < Math.ceil(showbanner.length / bannersPerPage) && (
            <button onClick={() => paginate(currentPage + 1)}>
              Next
            </button>
          )}
        </>
      )}
    </div>
    </div>
{/* end */}
</>
    )
}
export default wcuchild;