import { ToastContainer} from 'react-toastify';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";
import { urlApi } from '../../Context/url';
import './AcademicPartner.scss';


const AcaddmicChild = ({handleChange, handleSubmit, currentBanners, handleContentChange1, putHandler, deleteHandler, put, putBanner, formData1, showbanner, currentPage, bannersPerPage, generatePaginationButtons, paginate, clearImage}) => {
    return (
        <div className='main-conatiner' id='mtcont'>
            <div className='child-container'>
      <input
        type="file"
        onChange={(e) => handleChange(e, 'img')}
        name='img'
        className="form-input form-file-input"
        required
      />
      </div>
      <div className='btncontainer'>
      <button onClick={handleSubmit} id="bnbtn">
        Save Content
      </button>
      <ToastContainer />
    </div>

       {/* start */}
  <article className='article-height' id='article-height'>
      {currentBanners.map((data, index)=> <div className='main-content'>
        <div className='child-main-container'>
          <div className='cont1'>
          <span>{index + 1}. </span>
          <span><img src={`${urlApi}/${data.img}`} alt='imgname'/></span> 
          </div>
          <div className='cont2'>
          <FaEye /> <FaRegEdit onClick={()=> putHandler(data)}/> <RiDeleteBin5Fill onClick={()=> deleteHandler(data.id)}/>
          </div>
          </div>
      </div>)}
      </article>
      {put.id && (
        <div className='main-conatiner' id='main'>
        <div className='child-container' id='child'>
        <input
          type="file"
          accept="image/*"
          name="img1"
          onChange={handleContentChange1}
          className="tt"
        />
      </div>
      <button  id="bnbtn" onClick={putBanner}>
        Save Content
      </button>
      <ToastContainer />
    
    </div>
      )}


{/* Pagination controls */}
<div className="pagination">
  {showbanner.length > bannersPerPage && (
    <>
      {currentPage > 1 && (
        <button onClick={() => paginate(currentPage - 1)}>
          Previous
        </button>
      )}
      {generatePaginationButtons()}
      {currentPage < Math.ceil(showbanner.length / bannersPerPage) && (
        <button onClick={() => paginate(currentPage + 1)}>
          Next
        </button>
      )}
    </>
  )}
</div>

{/* end */}


        </div>
    )
}
export default AcaddmicChild;