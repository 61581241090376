const PageGetList = ({pages}) => {

    return (
        <>        
        <h1>Pages</h1>
            <ul>
                {pages.map(page => (
                    <li key={page.id}>
                        {page.title}
                        {page.subpages && (
                            <ul>
                                {page.subpages.map(subpage => (
                                    <li key={subpage.id}>{subpage.title}</li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
            </>

    )
}
export default PageGetList;