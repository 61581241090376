// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-toolbar.ql-snow {
  background-color: white !important;
}

.ql-editor {
  background-color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/Notice/Events.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;AACJ;;AAEA;EACI,kCAAA;AACJ","sourcesContent":[".ql-toolbar.ql-snow {\n    background-color: white !important;\n}\n\n.ql-editor {\n    background-color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
