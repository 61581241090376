import { useEffect, useState } from "react";
import OurteamChild from "./OurteamChild";
import { urlApi } from "../Context/url";

const Ourteam = () => {
    const [data, setData] = useState([]);

   useEffect (() => {
    const fetchData = async() => {
        try {
            const response = await fetch(`${urlApi}/faculties/`);
            const result = await response.json();
            setData(result);
        } catch(error) {
            console.log('Error fetching data', error)
        }
       
    }
    fetchData();
   }, [])



    return <OurteamChild data={data}/>
}
export default Ourteam;