import { useState, useEffect, useCallback } from 'react';
import { urlApi } from '../Context/url';
import './AcademicPartnerChild.scss';

const AcademicPartnerChild = ({data}) => {
    const itemsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(1);
  
    const totalItems = data.length;
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    const displayedItems =
      totalItems > itemsPerPage
        ? data.slice(startIndex, endIndex)
        : data;
  
    const totalPages = Math.ceil(totalItems / itemsPerPage);
  
    const handleNextPage = useCallback(() => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
      }, [totalPages]);
    
  
    const handlePrevPage = () => {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    useEffect(() => {
        const intervalId = setInterval(handleNextPage, 6000);
        return () => clearInterval(intervalId);
      }, [handleNextPage]);

    return (
        <>
        <br/>
            <h1 className='h1text1'>Association | Partnerships</h1>
            <div className="acdiv">
                {
                    displayedItems.map((data)=> {
                        return (
                            <>
                                <div className="acchild">
                                    <img src={`${urlApi}/${data.img}`} alt="img"/>
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}
export default AcademicPartnerChild;