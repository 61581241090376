import './TopNavChild.scss';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { CiPhone } from "react-icons/ci";

const TopNavChild = ({topNav}) => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(null);

  const handleDivClick = (data) => {
    setActivePage(data);
    if (data.link) {
      // Redirect to the specified link
      navigate(data.link);
    }
  };

    return (
         <>
            <div className="topnav-container">
            <div className="child">
                <SiAmazonsimpleemailservice class="email-icon"/>
                <span className="email-text">info@mupolicylab.edu.np</span>
            </div>
                    
                {
                    topNav.map((data) => {
                        return (
                            <div className={`topnav-child ${activePage === data ? 'active' : ''}`} onClick={() => handleDivClick(data)}>
                                {data.name}
                            </div>   
                        )
                    })
                }
               
               {/* <a href="https://app.ithenticate.com/en_us/login" className="topnav-child">
                iThenticate
                 </a>
                 */}
            </div>
            {/*<p className='phno'><CiPhone /> +977-083-525352</p>*/}
        </>
    )
}
export default TopNavChild;
