import { urlApi } from '../Context/url';
import './OurteamChild.scss';
import { TfiShiftLeftAlt, TfiShiftRightAlt  } from "react-icons/tfi";
import { useState, useEffect, useCallback } from 'react';

const OurteamChild = ({data}) => {
    // const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(()=> {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const itemsPerPage = screenWidth <= 480 || screenWidth <= 320 ? 1: 3;


  
    const totalItems = data.length;
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    const displayedItems =
      totalItems > itemsPerPage
        ? data.slice(startIndex, endIndex)
        : data;
  
    const totalPages = Math.ceil(totalItems / itemsPerPage);
  
    const handleNextPage = useCallback(() => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
      }, [totalPages]);
    
  
    const handlePrevPage = () => {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    useEffect(() => {
        const intervalId = setInterval(handleNextPage, 6000);
        return () => clearInterval(intervalId);
      }, [handleNextPage]);


// Define a function to generate the extra empty div
function generateExtraEmptyDiv(displayedItems) {
  const remainder = displayedItems.length % 3;
  const extraEmptyDivNeeded = remainder !== 0 && remainder !== 3;
  if (extraEmptyDivNeeded) {
      return <div className='child empty' key={`extra-empty`} />;
  }
  return null;
}

    return (
        <div className='parent'>
        <p className='under'>University Executive Team</p>

        <div className='parents'>
           {/* {
            displayedItems.map((data, index) => {
                return (
                    <div className='child' key={index}>
                    <img src={`${urlApi}/${data.img}`} alt='img' className='img'/>
                    <p className='txt'>{data.name}</p>
                    <p className='txt'>{data.designation}</p>
                </div>
                )
            })} */}

{
    displayedItems.map((data, index) => (
        <div className='child' key={index}>
            <img src={`${urlApi}/${data.img}`} alt='img' className='img'/>
            <p className='txt'>{data.name}</p>
            <p className='txt'>{data.designation}</p>
        </div>
    ))
}

{
    // Call the function to generate the extra empty div
    generateExtraEmptyDiv(displayedItems)
}
            

        </div>
        <TfiShiftLeftAlt  className='right-shift-data' onClick={handlePrevPage}/>
        <TfiShiftRightAlt className='left-shift-data' onClick={handleNextPage}/>
        </div>
    )
}
export default OurteamChild;
