import { useState, useEffect } from "react";
import TestomonialChild from "./TestomonialChild";
import { toast } from "react-toastify";
import { urlApi } from "../../Context/url";
import { getToken } from "../LocalStorageService";
const Testiomonial = () => {
  const [formData, setFormData] = useState({
    img: null,
    name: "",
    text: "",
  });

  ///////////////////////////////////////////////////////////////////////////////
  // for put
  const [formData1, setFormData1] = useState({
    img1: null, // To store the selected image file
    name1: "",
    text1: "",
  });

  const [showbanner, setShowBanner] = useState([]);
  const [put, setPut] = useState([]);

  // for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const bannersPerPage = 6; // You can adjust this based on your preference

  // for pagination
  // Calculate indexes for pagination
  const indexOfLastBanner = currentPage * bannersPerPage;
  const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
  const currentBanners = showbanner.slice(
    indexOfFirstBanner,
    indexOfLastBanner
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //
  // Add this function to generate pagination buttons dynamically
  const generatePaginationButtons = () => {
    const totalPages = Math.ceil(showbanner.length / bannersPerPage);
    const visiblePages = 18; // Number of visible pagination buttons

    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > visiblePages) {
      const half = Math.floor(visiblePages / 2);
      startPage = Math.max(currentPage - half, 1);
      endPage = Math.min(startPage + visiblePages - 1, totalPages);
      if (endPage - startPage + 1 < visiblePages) {
        startPage = endPage - visiblePages + 1;
      }
    }

    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          style={{
            fontWeight: currentPage === i ? "bold" : "normal",
            color: currentPage === i ? "red" : "white",
          }}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  // put request
  const putHandler = (data) => {
    setPut(data);
    // putBanner(data.id);
  };

  const putBanner = async () => {
    const accessToken = getToken().access_token;
    try {
      const formDataObject = new FormData();
      if (formData1.img1) {
        formDataObject.append("img", formData1.img1);
      }
      if (formData1.name1.trim() !== "") {
        formDataObject.append("name", formData1.name1 || "");
      }
      if (formData1.text1.trim() !== "") {
        formDataObject.append("text", formData1.text1 || "");
      }


      const response = await fetch(`${urlApi}/testimonials/${put.id}/`, {
        method: "PUT",
        body: formDataObject,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log("Data saved successfully");
        toast.success("Saved!");
        // Clear the form fields after a successful submission
        setFormData1({
          img1: null,
          name1: '',
          text1: ''
        });

        // Update the state with the modified banner
        const updatedShowBanner = showbanner.map((item) =>
          item.id === put.id
            ? {
                ...item,
                img: formData1.img1,
                name: formData1.name1,
                text: formData1.text1,
              }
            : item
        );

        setShowBanner(updatedShowBanner);
        setPut([]); // Clear the 'put' state
        await fetchBanners(); // Fetch banners after updating to update the list
      } else {
        const errorMessage = await response.text();
        console.error("Error saving data:", errorMessage);
        toast.error("Required fields not filled.", errorMessage);
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data", error.message);
    }
  };

  // Delete handler
  const deleteHandler = (data) => {
    deleteBanner(data);
  };

  // for delete banner

  const deleteBanner = async (id) => {
    const accessToken = getToken().access_token;
    try {
      const response = await fetch(`${urlApi}/testimonials/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        console.log("Data deleted successfully");
        toast.success("Deleted!");
        fetchBanners(); // Fetch banners after deleting to update the list
      } else {
        const errorMessage = await response.text();
        console.error("Error saving data:", errorMessage);
        toast.error("Required fields not filled.", errorMessage);
      }
    } catch (error) {
      console.error("Error deleting data:", error.message);
      toast.error("Error deleting data", error.message);
    }
  };

  // for get request
  const fetchBanners = async () => {
    try {
      const response = await fetch(`${urlApi}/testimonials/`);
      if (response.ok) {
        const data = await response.json();
        setShowBanner(data);
      } else {
        console.log("Error fetching banners");
      }
    } catch (error) {
      console.log("Error fetching banners:", error.message);
    }
  };

  useEffect(() => {
    const fetchAndSetBanners = async () => {
      await fetchBanners();
      setCurrentPage(1);
    };
    fetchAndSetBanners();
  }, []);

  const handleContentChange1 = (e) => {
    if (e.target.name === "img1") {
      setFormData1({ ...formData1, [e.target.name]: e.target.files[0] });
    } else {
      setFormData1({ ...formData1, [e.target.name]: e.target.value });
    }
  };

  ///////////////////////////////////////////////////////////////////

  const handleContentChange = (e) => {
    if (e.target.name === "img") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accessToken = getToken().access_token;
    try {
      const formDataObject = new FormData();
      formDataObject.append("img", formData.img);
      formDataObject.append("name", formData.name);
      formDataObject.append("text", formData.text);

      const response = await fetch(`${urlApi}/testimonials/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // 'Content-Type': 'multipart/form-data'
        },
        body: formDataObject,
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.detail);
      }

      toast.success("Data added successfully");
      await fetchBanners();
    } catch (error) {
      console.error("Error adding data", error);
      toast.error(error.message || "Error adding data");
    }
  };

  return (
    <TestomonialChild
      handleSubmit={handleSubmit}
      handleContentChange={handleContentChange}
      formData={formData}
      formData1={formData1}
      currentBanners={currentBanners}
      handleContentChange1={handleContentChange1}
      putHandler={putHandler}
      deleteHandler={deleteHandler}
      put={put}
      putBanner={putBanner}
      showbanner={showbanner}
      currentPage={currentPage}
      bannersPerPage={bannersPerPage}
      generatePaginationButtons={generatePaginationButtons}
      paginate={paginate}
    />
  );
};
export default Testiomonial;
