import React, { useState, useEffect, useCallback } from 'react';
import './childpdf.scss';
import { RxCross1 } from 'react-icons/rx';
import { urlApi } from '../Context/url';

const Childpdf = ({ data }) => {
  const [pop, setPop] = useState(false);
  const [header, setHeader] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const itemsPerPage = screenWidth <= 480 ? 1 : screenWidth <= 800 ? 3 : 5;

  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  const handleClick = (dt) => {
    setPop(true);
    setHeader(dt);
  };

  const closePopUp = () => {
    setPop(false);
  };

  const totalItems = data.length;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems =
    totalItems > itemsPerPage ? data.slice(startIndex, endIndex) : data;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <>
      <div className="pdfparent">
        <div className="pdfchild">
          <h1>Academic Calendar</h1>
          <button>View All</button>
        </div>
        {displayedItems.map((dt, index) => (
          <div className="pdfchild" key={index}>
            <img
              src={`${urlApi}/${dt.img}`}
              width="30"
              style={{ paddingBottom: '10px' }}
              alt="imag"
              onClick={() => handleClick(dt)}
            />
            <p className="title">{dt.name}</p>
            <p className="subtitle">{dt.datedata}</p>
          </div>
        ))}
      </div>

      {pop && (
        <div className="overlay" onClick={closePopUp}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closePopUp}>
              <RxCross1 className="crossbtn" />
            </span>
            <span className="htext-keep-moving">
              <p>{`${header.name} - ${header.datedata}`}</p>
            </span>
            {/* PDF Viewer */}
            <iframe
              title="PDF Viewer"
              src={`${urlApi}/${header.file}`}
              width="100%"
              height="500px"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Childpdf;
