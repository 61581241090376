// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

select, input, button {
  margin-bottom: 10px;
  padding: 6px;
  border: 4px solid white;
  font-size: 16px;
}

button {
  padding: 0px 15px;
  border: 3px solid white;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/GalleryImage.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;EACA,aAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;;AAEE;EACE,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;AACJ;;AACE;EACE,iBAAA;EACA,uBAAA;AAEJ","sourcesContent":[".gallery-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  form {\n    display: flex;\n    flex-direction: row;\n    gap: 4px;\n  }\n  \n  select, input, button {\n    margin-bottom: 10px;\n    padding: 6px;\n    border: 4px solid white;\n    font-size: 16px;\n  }\n  button {\n    padding: 0px 15px;\n    border: 3px solid white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
