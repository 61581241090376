// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-div {
  display: flex;
  background-color: #0467a0;
  padding: 10px;
}
.footer-div .footer p {
  color: white;
  padding: 2px;
  margin: 4px 40px;
}
.footer-div .footer h2 {
  color: white;
  margin: 4px 30px;
  padding: 8px;
}
.footer-div .footer a {
  color: white !important;
  text-decoration: none;
}

.algn {
  text-align: center;
  display: block;
  -webkit-text-decoration: blanchedalmond;
          text-decoration: blanchedalmond;
  background-color: red;
  color: white;
  padding: 4px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/FooterChild.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,aAAA;AACJ;AAEQ;EACI,YAAA;EACA,YAAA;EACA,gBAAA;AAAZ;AAGQ;EACI,YAAA;EACA,gBAAA;EACA,YAAA;AADZ;AAGQ;EACI,uBAAA;EACA,qBAAA;AADZ;;AAMA;EACI,kBAAA;EACA,cAAA;EACA,uCAAA;UAAA,+BAAA;EACA,qBAAA;EACA,YAAA;EACA,YAAA;AAHJ","sourcesContent":[".footer-div {\n    display: flex;\n    background-color: #0467a0;\n    padding: 10px;\n\n    .footer {\n        p {\n            color: white;\n            padding: 2px;\n            margin: 4px 40px;\n        }\n\n        h2 {\n            color: white;\n            margin: 4px 30px;\n            padding: 8px;\n        }\n        a {\n            color: white !important;\n            text-decoration: none;\n        }\n        \n    }\n}\n.algn {\n    text-align: center;\n    display: block;\n    text-decoration: blanchedalmond;\n    background-color: red;\n    color: white;\n    padding: 4px;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
