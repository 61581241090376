// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-panel {
  max-width: 100%;
  padding-top: 10px;
  background-color: #9cdcf8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  margin: 0;
  height: 38rem;
}
.admin-panel input[type=file] {
  border: 2px solid white;
  overflow: hidden;
}

.hide-scrollbar {
  overflow-x: hidden !important; /* Hide horizontal scrollbar */
}

.leftchild p {
  padding: 8px 40px;
  font-size: 16px;
  margin-left: 10px;
  background-color: #0467a0;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.leftchild {
  width: 20%;
  padding-top: 8px;
  margin: 10px;
}

.pd {
  margin: 2px;
}

#ppd {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/AdminPanel.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,uCAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;AACF;AACE;EACE,uBAAA;EACA,gBAAA;AACJ;;AAGA;EACE,6BAAA,EAAA,8BAAA;AAAF;;AAGA;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAAF;;AAOA;EACE,UAAA;EACA,gBAAA;EACA,YAAA;AAJF;;AAOA;EACE,WAAA;AAJF;;AAOA;EACE,qBAAA;AAJF","sourcesContent":[".admin-panel {\n  max-width: 100%;\n  padding-top: 10px;\n  background-color: #9cdcf8;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  display: flex;\n  flex-direction: row;\n  margin: 0;\n  height: 38rem;\n\n  input[type='file'] {\n    border: 2px solid white;\n    overflow: hidden;\n  }\n}\n\n.hide-scrollbar {\n  overflow-x: hidden !important; /* Hide horizontal scrollbar */\n}\n\n.leftchild p {\n  padding: 8px 40px;\n  font-size: 16px;\n  margin-left: 10px;\n  background-color: #0467a0;\n  color: #fff;\n  border: none;\n  border-radius: 2px;\n  cursor: pointer;\n}\n\n// .leftchildcontent {\n//   width: 75%;\n// }\n\n.leftchild {\n  width: 20%;\n  padding-top: 8px;\n  margin: 10px;\n}\n\n.pd {\n  margin: 2px;\n}\n\n#ppd {\n  background-color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
