// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#textareabox {
  height: 8rem;
  width: 28rem;
}

#textareachild {
  height: 4rem;
  width: 18rem;
}

.article-main {
  width: 86%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/WhyChooseUs/wcuchild.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":["#textareabox {\n    height: 8rem;\n    width: 28rem;\n}\n\n#textareachild {\n    height: 4rem;\n    width: 18rem;\n}\n\n.article-main {\n    width: 86%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
