// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.htext-keep-moving {
  background-color: white;
  padding-bottom: 0 !important;
  padding-top: 2px !important;
  justify-content: center !important;
  display: flex;
  width: 100% !important;
  color: #0467a0;
  text-decoration: none !important;
  margin: 0 !important;
}
.htext-keep-moving p {
  padding: 20px;
  padding-bottom: 38px;
  font-size: large;
  background-color: #0467a0;
  color: white;
}

.imginsidediv {
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 480px) {
  .imginsidediv {
    width: 90%;
    object-fit: contain;
  }
}`, "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAEE;EACE,uBAAA;EACA,4BAAA;EACA,2BAAA;EACA,kCAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,gCAAA;EACA,oBAAA;AADJ;AAGI;EACI,aAAA;EACA,oBAAA;EACA,gBAAA;EACA,yBAAA;EACA,YAAA;AADR;;AAKA;EACI,WAAA;EACA,mBAAA;AAFJ;;AAOA;EACI;IACI,UAAA;IACA,mBAAA;EAJN;AACF","sourcesContent":["\n\n  .htext-keep-moving {\n    background-color: white;\n    padding-bottom: 0 !important;\n    padding-top: 2px !important;\n    justify-content: center !important;\n    display: flex;\n    width: 100% !important;\n    color: #0467a0;\n    text-decoration: none !important;\n    margin: 0 !important;\n\n    p {\n        padding: 20px;\n        padding-bottom: 38px;\n        font-size: large;\n        background-color: #0467a0;\n        color: white;\n    }\n}\n\n.imginsidediv {\n    width: 100%;\n    object-fit: contain;\n}\n\n\n\n@media screen and (max-width: 480px) {\n    .imginsidediv {\n        width: 90%;\n        object-fit: contain;\n    }\n    \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
