import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { urlApi } from "../Context/url";
import "./UniversityCampusAround.scss";
import { getToken } from "./LocalStorageService";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";

const UniversityCampusAround = () => {
    const [formData, setFormData] = useState({
        heading: "",
        address: "",
        link: "",
        img: null, // To store the selected image file
    });

    ///////////////////////////////////////////////////////////////////////////////
    // for put
    const [formData1, setFormData1] = useState({
        heading1: "",
        address1: "",
        link1: "",
        img1: null, // To store the selected image file
    });

    const [showbanner, setShowBanner] = useState([]);
    const [put, setPut] = useState([]);

    // for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const bannersPerPage = 6; // You can adjust this based on your preference

    // for pagination
    // Calculate indexes for pagination
    const indexOfLastBanner = currentPage * bannersPerPage;
    const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
    const currentBanners = showbanner.slice(
        indexOfFirstBanner,
        indexOfLastBanner
    );

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //
    // Add this function to generate pagination buttons dynamically
    const generatePaginationButtons = () => {
        const totalPages = Math.ceil(showbanner.length / bannersPerPage);
        const visiblePages = 18; // Number of visible pagination buttons

        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > visiblePages) {
            const half = Math.floor(visiblePages / 2);
            startPage = Math.max(currentPage - half, 1);
            endPage = Math.min(startPage + visiblePages - 1, totalPages);
            if (endPage - startPage + 1 < visiblePages) {
                startPage = endPage - visiblePages + 1;
            }
        }

        const buttons = [];
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => paginate(i)}
                    style={{
                        fontWeight: currentPage === i ? "bold" : "normal",
                        color: currentPage === i ? "red" : "white",
                    }}
                >
                    {i}
                </button>
            );
        }

        return buttons;
    };

    // put request
    const putHandler = (data) => {
        setPut(data);
        // putBanner(data.id);
    };

    const putBanner = async () => {
        const accessToken = getToken().access_token;
        try {
            const formDataObject = new FormData();
              if(formData1.heading1.trim() !== '') {
                formDataObject.append('heading', formData1.heading1 || '')
              }
              if(formData1.address1.trim() !== '') {
                formDataObject.append('address', formData1.address1 || '')
              }
              if(formData1.link1.trim() !== '') {
                formDataObject.append('link', formData1.link1 || '')
              }
        
            if (formData1.img1) {
                formDataObject.append('img', formData1.img1);
            }
    
            const response = await fetch(`${urlApi}/universitycampuses/${put.id}/`, {
                method: "PUT",
                body: formDataObject,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            if (response.ok) {
                console.log("Data saved successfully");
                toast.success("Saved!");
                // Clear the form fields after a successful submission
                setFormData1({
                    heading1: "",
                    address1: "",
                    link1: "",
                    img1: null,
                });
    
                // Update the state with the modified banner
                const updatedShowBanner = showbanner.map((item) =>
                    item.id === put.id
                        ? {
                            ...item,
                            heading: formData1.heading1,
                            address: formData1.address1,
                            link: formData1.link1,
                            img: formData1.img1,
                        }
                        : item
                );
    
                setShowBanner(updatedShowBanner);
                setPut([]); // Clear the 'put' state
                await fetchBanners(); // Fetch banners after updating to update the list
            } else {
                const errorMessage = await response.text();
                console.error("Error saving data:", errorMessage);
                toast.error("Required fields not filled.", errorMessage);
            }
        } catch (error) {
            console.error("Error saving data:", error.message);
            toast.error("Error saving data", error.message);
        }
    };
    
    

    // Delete handler
    const deleteHandler = (data) => {
        deleteBanner(data);
    };

    // for delete banner

    const deleteBanner = async (id) => {
        const accessToken = getToken().access_token;
        try {
            const response = await fetch(`${urlApi}/universitycampuses/${id}/`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                console.log("Data deleted successfully");
                toast.success("Deleted!");
                fetchBanners(); // Fetch banners after deleting to update the list
            } else {
                const errorMessage = await response.text();
                console.error("Error saving data:", errorMessage);
                toast.error("Required fields not filled.", errorMessage);
            }
        } catch (error) {
            console.error("Error deleting data:", error.message);
            toast.error("Error deleting data", error.message);
        }
    };

    // for get request
    const fetchBanners = async () => {
        try {
            const response = await fetch(`${urlApi}/universitycampuses/`);
            if (response.ok) {
                const data = await response.json();
                setShowBanner(data);
                
            } else {
                console.log("Error fetching banners");
            }
        } catch (error) {
            console.log("Error fetching banners:", error.message);
        }
    };

    useEffect(() => {
        const fetchAndSetBanners = async () => {
            await fetchBanners();
            setCurrentPage(1);
        };
        fetchAndSetBanners();
    }, []);

    ///////////////////////////////////////////////////////////////////

    const handleFileChange1 = (e) => {
        if (e.target.name === "img1") {
            setFormData1({ ...formData1, [e.target.name]: e.target.files[0] });
        } else {
            setFormData1({ ...formData1, [e.target.name]: e.target.value });
        }
    };

    const handleChange = (e) => {
        if (e.target.name === "img") {
            setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const accessToken = localStorage.getItem('accessToken');
        const accessToken = getToken().access_token;

        try {
            const formDataObject = new FormData();
            formDataObject.append("heading", formData.heading);
            formDataObject.append("address", formData.address);
            formDataObject.append("link", formData.link);
            formDataObject.append("img", formData.img);

            const response = await fetch(`${urlApi}/universitycampuses/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formDataObject,
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            //handle successful response
            toast.success("Data added successfully");
            await fetchBanners();
        } catch (error) {
            console.error("Error adding data:", error);
            // Handle error
            toast.error("Error adding data");
        }
    };

    return (
        <div className="gallery-container">
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Enter Heading"
                    name="heading"
                    value={formData.heading}
                    onChange={handleChange}
                    className="tt"
                />
                <input
                    type="text"
                    placeholder="Enter Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="tt"
                />
                <input
                    type="text"
                    placeholder="Enter Link"
                    name="link"
                    value={formData.link}
                    onChange={handleChange}
                    className="tt"
                />
                <input
                    type="file"
                    accept="image/*"
                    name="img"
                    onChange={handleChange}
                    className="tt"
                />
                <button type="submit">Add</button>
            </form>
            <ToastContainer />

            {/* start */}
            <article className="article-height">
                {currentBanners.map((data, index) => (
                    <div className="main-content">
                        <div className="child-main-container">
                            <div className="cont1">
                                <span>{index + 1}. </span>
                                <span>{data.heading}</span>
                            </div>
                            <div className="cont2">
                                <FaEye /> <FaRegEdit onClick={() => putHandler(data)} />{" "}
                                <RiDeleteBin5Fill onClick={() => deleteHandler(data.id)} />
                            </div>
                        </div>
                    </div>
                ))}
            </article>
            {put.id && (
                <div className="main-conatiner" id="main">
                    <div className="child-container" id="child">
                        <input
                        type="text"
                        name="heading1"
                        value={formData1.heading1 || put.heading}
                        onChange={handleFileChange1}
                        className="tt"
                    />
                    </div>
                    <div className="child-container" id="child">
                        <input
                        type="text"
                        placeholder="Enter Address"
                        name="address1"
                        value={formData1.address1 || put.address}
                        onChange={handleFileChange1}
                        className="tt"
                    />
                    </div>
                    <div className="child-container" id="child">
                        <input
                        type="text"
                        placeholder="Enter Link"
                        name="link1"
                        value={formData1.link1 || put.link}
                        onChange={handleFileChange1}
                        className="tt"
                    />
                        <div className="child-container" id="child">
                        <input
                        type="file"
                        accept="image/*"
                        name="img1"
                        onChange={handleFileChange1}
                        className="tt"
                    />
                        </div>
                    </div>
                    <button id="bnbtn" onClick={putBanner}>
                        Save Content
                    </button>
                    <ToastContainer />
                </div>
            )}

            {/* Pagination controls */}
            <div className="pagination">
                {showbanner.length > bannersPerPage && (
                    <>
                        {currentPage > 1 && (
                            <button onClick={() => paginate(currentPage - 1)}>
                                Previous
                            </button>
                        )}
                        {generatePaginationButtons()}
                        {currentPage < Math.ceil(showbanner.length / bannersPerPage) && (
                            <button onClick={() => paginate(currentPage + 1)}>Next</button>
                        )}
                    </>
                )}
            </div>

            {/* end */}
        </div>
    );
};
export default UniversityCampusAround;
