import React from 'react'
import { urlApi } from '../Context/url'
import './ourTeam.scss';

const ourTeam = ({data}) => {
  return (
    <div className='centerdiv'>
    {data.name === 'Our Team' && data.dept && data.dept.length > 0 && (
        <>
        <div>
        <p className='pitem' dangerouslySetInnerHTML={{ __html: data.content1 }}></p>
        </div>
            <div className='centeritem1'>
                <div className='centeritemchild'>
                    {data.dept.map((data) => (
                        <>
                            {data.position.position === 'COORDINATOR' ? (
                                <p className='dean'>COORDINATOR</p>

                                ) : null}

                                {data.position.position === 'COORDINATOR' ? (
                                <img src={`${urlApi}/${data.img}`} alt={data.title} /> 
                                ) : null}

                                {data.position.position === 'COORDINATOR' ? (
                                    
                                    <div className='childct'>
                                        <p>{data.title}<br/>{data.designation}</p>
                                    </div>
                                   ) : null}
                             <p className='pitem' dangerouslySetInnerHTML={{ __html: data.subject }}></p>
                        </>
                    ))}
                </div>
            </div>
          

            {/* <div className='centeritem2'>
                <p className='coordinate'>HOD/COORDINATOR</p>
                {data.dept.filter(item => item.position.position === 'COORDINATOR').map((item, index) => (
                    <div key={index}>
                        <div className='centeritemchild'>
                            <br />
                            <br />
                            <img src={`${urlApi}/${item.img}`} alt={item.title} />
                        </div>
                        <div className='childct'>
                            <p>{item.title}<br/>{item.designation}<br/>{item.subject}</p>
                        </div>
                    </div>
                ))}
            </div>
 */}

            {/* <div className='centeritem3'>
                <p className='faculty'>TEACHING FACULTY</p>
                {data.dept.filter(item => item.position.position === 'FACULTY').map((item, index) => (
                    <div key={index}>
                        <div className='centeritemchild'>
                            <br />
                            <br />
                            <img src={`${urlApi}/${item.img}`} alt={item.title} />
                        </div>
                        <div className='childct'>
                        <p>{item.title}<br/>{item.designation}<br/>{item.subject}</p>
                        </div>
                    </div>
                ))}
            </div> */}


            <div className='centeritem4'>
                <p className='faculty'>POLICY SPECIALIST</p>
                {data.dept.filter(item => item.position.position === 'POLICY SPECIALIST').map((item, index) => (
                    <div key={index}>
                        <div className='centeritemchild'>
                            <br />
                            <br />
                            <img src={`${urlApi}/${item.img}`} alt={item.title} />
                            {/* <p className='pitem'>{item.subject}</p> */}
                        </div>
                        <div className='childct'>
                        <p>{item.title}<br/>{item.designation}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className='centeritem4'>
                <p className='faculty'>STAFF</p>
                {data.dept.filter(item => item.position.position === 'STAFF').map((item, index) => (
                    <div key={index}>
                        <div className='centeritemchild'>
                            <br />
                            <br />
                            <img src={`${urlApi}/${item.img}`} alt={item.title} />
                            {/* <p className='pitem'>{item.subject}</p> */}
                        </div>
                        <div className='childct'>
                        <p>{item.title}<br/>{item.designation}</p>
                        </div>
                    </div>
                ))}
            </div>


            {/* <div className='centeritem5'>
                <p className='faculty'>OFFICE HELPER</p>
                {data.dept.filter(item => item.position.position === 'OFFICE HELPER').map((item, index) => (
                    <div key={index}>
                       
                        <div className='centeritemchild'>
                            <br />
                            <br />
                            <img src={`${urlApi}/${item.img}`} alt={item.title} />
                        </div>
                        <div className='childct'>
                            <p>{item.title}</p>
                        </div>
                    </div>
                ))}
            </div> */}



        {/* <div className='centeritem2'>
            <p className='coordinate'>FACULTY MEMBER</p>
            {data.dept.slice(1, 4).map((item, index) => (
                <div key={index}>
                    <div className='centeritemchild'>
                    <br/>
                    <br/>
                    <img src={`${urlApi}/${item.img}`} alt={item.title} />
                    </div>
                    <div className='childct'>
                        <p>{item.title}</p>
                    </div>
                </div>
            ))}
        </div> */}


            {/* {data.dept.slice(4, 5).map((item, index) => (
                <div className='centeritem3' key={index}>

                    <div className='centeritemchild'>
                    <img src={`${urlApi}/${item.img}`} alt={item.name} />
                    
                    </div>
                    
                </div>
            ))} */}
        </>
    )}
</div>

  )
}

export default ourTeam
