import { useState } from 'react';
import './ImageGalleryChild.scss';
import { urlApi } from '../Context/url';
import { RxCross1 } from "react-icons/rx";
import conferenceImage from './events.jpg';
import libraryImage from './library.jpg';
import workingSpace from './working-space.jpg';
import executiveRoom from './executive-room.jpg';
import program from './program.jpeg';
import '../Events/EventChild.scss';

const ImageGalleryChild = ({data}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    

    const openModel = (category) => {
        setSelectedCategory(category);
        setIsModalOpen(true);
        
    }
    const closeModel = () => {
        setSelectedCategory(null);
        setIsModalOpen(false);
    }

        const filteredData = selectedCategory
        ? data.filter((item) => item.category === selectedCategory)
        : data;

    return (
        <>
            <p className='imagegallery'>IMAGE GALLERY</p>
            <div className="imageGalleryParent">

                <div className="imageChild">
                    <img src={conferenceImage} alt='imge' onClick={() => openModel('Conference_Hall')}/>
                    <p>Event Program</p>
                </div>




                <div className="imageChild">
                    <img src={workingSpace} alt='img3' onClick={() => openModel('Working_Space')}/>
                    <p>Working Space</p>
                </div>


                <div className="imageChild">
                    <img src={executiveRoom} alt='img4' onClick={() => openModel('Executive_Room')}/>
                    <p>Executive Room</p>
                </div>

                <div className="imageChild">
                    <img src={libraryImage} alt='img2' onClick={() => openModel('Library')}/>
                    <p>Central Library Dream Project (2023-2025)</p>
                </div>

                <div className="imageChild">
                    <img src={program} alt='img4' onClick={() => openModel('Program_Snap')}/>
                    <p>Program Snap</p>
                </div>
            </div>

            {isModalOpen && (
                    <>
                    <div className="overlay" onClick={closeModel}>
                    <div className="popup small-popup" onClick={(e) => e.stopPropagation()}>
                        {filteredData.map((data, index) => (
                        <div
                        className='child-box small-box'
                        key={index}
                        >
                            <img
                            src={`${urlApi}/${data.img}`}
                            alt="img1"
                            />
                            {/* <p>{data.name}</p> */}
                        </div>
                        ))}
                    
                    <span onClick={closeModel}><RxCross1 className="cross-btn" /></span>
                    </div>
                    </div>
                </>
                )}
        </>
    )
}
export default ImageGalleryChild;