// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tt {
  margin-bottom: 10px;
  border: 4px solid white;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Admin/UniversityCampusAround.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,uBAAA;EACA,eAAA;AACJ","sourcesContent":[".tt {\n    margin-bottom: 10px;\n    border: 4px solid white;\n    font-size: 12px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
