import TestimonialChild from "./TestimonialChild";
import { urlApi } from "../Context/url";
import { useEffect, useState } from "react";
const Testimonial = () => {
    const [testimonial, setTestimonial] = useState([]);

    useEffect(()=> {
        const fetchData = async () => {
            try {
                const response = await fetch (`${urlApi}/testimonials/`);
                const result = await response.json();
                setTestimonial(result);
            } catch (error) {
                console.log('Error fetching data: ', error);
            }
        };
        fetchData();
    }, [])

    

    return (
        <>
            <TestimonialChild testimonial={testimonial}/>
        </>
    )
}
export default Testimonial;