import "./BannerSideContentChild.scss";
import { urlApi } from "../Context/url";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useState } from "react";
import { RxCross1 } from "react-icons/rx";

const BannerSideContentChild = ({ bannerchild }) => {
  const [pop, setPop] = useState(false);
  const [headerdata, setheaderdata] = useState("");

  const [popupContent, setPopupContent] = useState("");
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = bannerchild.length;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedItems =
    totalItems > itemsPerPage
      ? bannerchild.slice(startIndex, endIndex)
      : bannerchild;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : totalPages
    );
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  const popUpHandler = (body, header) => {
    setPopupContent(body);
    setheaderdata(header);
    setPop(true);
  };

  const closePopUp = () => {
    setPop(false);
  };

  return (
    <>
      <div className="banner-side-content-container">
        {displayedItems.map((data, index) => (
          <div className="banner-side-content-child" key={index}>
            <img
              src={`${urlApi}/${data.img}`}
              alt="imgg"
              onClick={() => popUpHandler(data.body, data.header)}
            />
            
            <div>
              <p>
                <span>{data.header}</span>
              </p>
              <p>{data.text}</p>
            </div>
          </div>
        ))}
        <IoIosArrowDown className="right-shift" onClick={handleNextPage} />
        <IoIosArrowUp className="left-shift" onClick={handlePrevPage} />
      </div>
        

      {pop && (
        <div className="overlay" onClick={closePopUp}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closePopUp}><RxCross1  className="crossbtn"/></span>
            <span className="htext-keep-moving"><p>{headerdata}</p></span>
            <p dangerouslySetInnerHTML={{ __html: popupContent }}></p>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerSideContentChild;
