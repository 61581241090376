import { useEffect, useState } from "react";
import { urlApi } from "../Context/url";
import MiddleNavChild from "./MiddleNavChild";

const MiddleNav = () => {
    const [middleNav, setMiddleNav] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${urlApi}/middlenav/`);
                console.log(response)
                const result = await response.json();
                setMiddleNav(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <MiddleNavChild middleNav={middleNav}/>
        </>
    )
}
export default MiddleNav;