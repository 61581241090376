import { useEffect, useState } from "react";
import ImageGalleryChild from "./ImageGalleryChild";
import { urlApi } from "../Context/url";

const ImageGallery = () => {
    const [data, setData] = useState([]);


    useEffect(()=> {
        const fetchData = async() => {
            try {
                const response = await fetch(`${urlApi}/image/`);
                const result = await response.json();
                setData(result);
    
            } catch(error) {
                console.log('Error Loading', error)
            }
        }
        fetchData();
    }, [])
    
    return (
        <>
            <ImageGalleryChild data={data}/>
        </>
    )
}
export default ImageGallery;