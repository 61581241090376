import AcademicPartner from "./AcademicPartner/AcademicPartner";
import BannerSideContent from "./BannerSideContent/BannerSideContent";
import EndBannerChild from "./EndBanner/EndBannerChild";
import Event from "./Events/Event";
import ImageGallery from "./ImageGallery/ImageGallery";
import Notice from "./Notice/Notice";
import Ourteam from "./OurTeam/Ourteam";
import Testimonial from "./Testimonial/Testimonial";
import UniversityCampuses from "./UniversityCampuses/UniversityCampuses";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs";
import Parentpdf from "./pdfview/parentpdf";

const Home = () => {
    return (
        <>
        <BannerSideContent />
        <br />
        <UniversityCampuses />
        <br />
        <Event />
        <Notice/>
        <WhyChooseUs/>
        <Ourteam/>
        <br />
        <Parentpdf/>
        <ImageGallery />
        <br />
        <EndBannerChild />
        <br />
        <Testimonial />
        <br />
        <AcademicPartner />
        <br />
        </>
    )
}
export default Home;