import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./GalleryImage.scss"; // Import your CSS file
import { urlApi } from "../Context/url";
import { getToken } from "./LocalStorageService";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";


const GalleryImage = () => {
  const [category, setCategory] = useState('');
  const [file, setFile] = useState(null);



  ///////////////////////////////////////////////////////////////////////////////
   // for put
   const [category1, setCategory1] = useState('');
  const [file1, setFile1] = useState(null);
  

   const [showbanner, setShowBanner] = useState([])
   const [put, setPut] = useState([])
   
   // for pagination
   const [currentPage, setCurrentPage] = useState(1);
   const bannersPerPage = 6; // You can adjust this based on your preference

   
 // for pagination
 // Calculate indexes for pagination
 const indexOfLastBanner = currentPage * bannersPerPage;
 const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
 const currentBanners = showbanner.slice(indexOfFirstBanner, indexOfLastBanner);

 // Change page
 const paginate = (pageNumber) => setCurrentPage(pageNumber);


 //  
// Add this function to generate pagination buttons dynamically
const generatePaginationButtons = () => {
 const totalPages = Math.ceil(showbanner.length / bannersPerPage);
 const visiblePages = 18; // Number of visible pagination buttons

 let startPage = 1;
 let endPage = totalPages;

 if (totalPages > visiblePages) {
   const half = Math.floor(visiblePages / 2);
   startPage = Math.max(currentPage - half, 1);
   endPage = Math.min(startPage + visiblePages - 1, totalPages);
   if (endPage - startPage + 1 < visiblePages) {
     startPage = endPage - visiblePages + 1;
   }
 }

 const buttons = [];
 for (let i = startPage; i <= endPage; i++) {
   buttons.push(
     <button
       key={i}
       onClick={() => paginate(i)}
       style={{ fontWeight: currentPage === i ? 'bold' : 'normal', color: currentPage === i ? 'red' : 'white', }}
     >
       {i}
     </button>
   );
 }

 return buttons;
};


 // put request
 const putHandler = (data) => {
   setPut(data);
   // putBanner(data.id);
 }

 const handleFileChange1 = (e) => {
   setFile1(e.target.files[0]);
 };


 const putBanner = async () => {
   const accessToken = getToken().access_token
   try {
     const formData = new FormData();
     // formData.append('img', img1);
     // formData.append('header', header1); // Append header value
     // formData.append('text', text1);   // Append text value
     if(file1) {
       formData.append('img', file1)
      //  console.log('file', file1)
     }
     if(category1) {
       formData.append('category', category1)
      //  console.log('amrit', category1)
     }

     const response = await fetch(`${urlApi}/image/${put.id}/`, {
       method: 'PUT',
       body: formData,
       headers: {
         'Authorization': `Bearer ${accessToken}`,
     },
     });

     if (response.ok) {
       console.log('Data saved successfully');
       toast.success('Saved!');
        // Clear the form fields after a successful submission
        setFile1(null);
        setCategory1('');
        // Update the state with the modified banner
        const updatedShowBanner = showbanner.map(item =>
         item.id === put.id ? { ...item, category: category1, img: file1 } : item
         );
         
         setShowBanner(updatedShowBanner);
         setPut([]); // Clear the 'put' state
         await fetchBanners(); // Fetch banners after deleting to update the list

     } else {
       // const errorMessage = await response.text();
       // console.error('Error saving data:', errorMessage);
       // notifyError('Required fields not filled.', errorMessage);
       const errorMessage = await response.text();
       console.error('Error saving data:', errorMessage);
       toast.error('Required fields not filled.', errorMessage);
     }
   } catch (error) {
     console.error('Error saving data:', error.message);
     toast.error('Error saving data', error.message);
   }
       
     }



 // Delete handler
 const deleteHandler = (data) => {
   deleteBanner(data);
 }

 // for delete banner

 const deleteBanner = async (id) => {
   const accessToken = getToken().access_token
   try {
     const response = await fetch(`${urlApi}/image/${id}/`, {
       method: 'DELETE',
       headers: {
         'Authorization': `Bearer ${accessToken}`,
     },
     });
     if (response.ok) {
       console.log('Data deleted successfully');
       toast.success('Deleted!');
       fetchBanners(); // Fetch banners after deleting to update the list
     } else {
       const errorMessage = await response.text();
       console.error('Error saving data:', errorMessage);
       toast.error('Required fields not filled.', errorMessage);
     }
   } catch (error) {
     console.error('Error deleting data:', error.message);
     toast.error('Error deleting data', error.message);
   }
       
     }
   

 // for get request
 const fetchBanners = async () => {
   try {
     const response = await fetch(`${urlApi}/image/`);
     if(response.ok) {
       const data = await response.json();
       setShowBanner(data);
       
       
     } else {
       console.log('Error fetching banners')
     }
   } catch(error) {
     console.log('Error fetching banners:', error.message);
   }
 }

 useEffect(() => {
   const fetchAndSetBanners = async () => {
     await fetchBanners();
     setCurrentPage(1);
   };
   fetchAndSetBanners();
 }, []);


///////////////////////////////////////////////////////////////////




  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  }
  const handleCategoryChange1 = (e) => {
    setCategory1(e.target.value);
    console.log(e.target.value)
  }


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const accessToken = localStorage.getItem('accessToken');
    const accessToken = getToken().access_token
    const formData = new FormData();
    formData.append('category', category);
    formData.append('img', file);
    
    try {
      const response = await fetch(`${urlApi}/image/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
      },
        body: formData,
      });

      if (response.ok) {
        toast.success('Image uploaded successfully!');
        await fetchBanners();
      } else {
        toast.error(`Error uploading image: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  }

  return (
    <div className="gallery-container">
      <form onSubmit={handleSubmit}>
        <select value={category} onChange={handleCategoryChange}>
          <option value="" disabled>Select a category</option>
          <option value='Conference_Hall'>Conference Hall</option>
          <option value='Library'>Library</option>
          <option value='Working_Space'>Working Space</option>
          <option value='Executive_Room'>Executive Room</option>
          <option value='Program_Snap'>Program Snap</option>
        </select>
        <input type="file" accept="image/*" onChange={handleFileChange} />
        <button type="submit">Add</button>
      </form>
      <ToastContainer />

       {/* start */}
  <article className='article-height'>
      {currentBanners.map((data, index)=> <div className='main-content'>
        <div className='child-main-container'>
          <div className='cont1'>
          <span>{index + 1}. </span>
          <span>{data.category}</span> 
          </div>
          <div className='cont2'>
          <FaEye /> <FaRegEdit onClick={()=> putHandler(data)}/> <RiDeleteBin5Fill onClick={()=> deleteHandler(data.id)}/>
          </div>
          </div>
      </div>)}
      </article>
      {put.id && (
        <div className='main-conatiner' id='main'>
        <div className='child-container' id='child'>
      {/* <textarea
        type="text"
        name='header1'
        value={category1 || put.category}
        onChange={(e) => setCategory(e.target.value)}
        className="form-input"
        placeholder='Header: No more than 30 characters only'
      /> */}
      <select value={category1 || put.category} onChange={handleCategoryChange1} name="category1">
          <option value="" disabled>Select a category</option>
          <option value='Conference_Hall'>Conference Hall</option>
          <option value='Library'>Library</option>
          <option value='Working_Space'>Working Space</option>
          <option value='Executive_Room'>Executive Room</option>
          <option value='Program_Snap'>Program Snap</option>
        </select>
      </div>
      <div className='child-container' id='child'>
      
    </div>
    <div className='child-container' id='child'>
      <input
        type="file"
        name='file1'
        onChange={handleFileChange1}
        className="form-input form-file-input"
        required
      />
      </div>
      <div>
      </div>
     
      <button  id="bnbtn" onClick={putBanner}>
        Save Content
      </button>
      <ToastContainer />
    
    </div>
      )}


{/* Pagination controls */}
<div className="pagination">
  {showbanner.length > bannersPerPage && (
    <>
      {currentPage > 1 && (
        <button onClick={() => paginate(currentPage - 1)}>
          Previous
        </button>
      )}
      {generatePaginationButtons()}
      {currentPage < Math.ceil(showbanner.length / bannersPerPage) && (
        <button onClick={() => paginate(currentPage + 1)}>
          Next
        </button>
      )}
    </>
  )}
</div>

{/* end */}

    </div>
  );
}

export default GalleryImage;
