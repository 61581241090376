import './TestimonialChild.scss';
import { TfiShiftLeftAlt, TfiShiftRightAlt  } from "react-icons/tfi";
import { urlApi } from '../Context/url';
import { useEffect, useState, useCallback } from 'react';

const TestimonialChild = ({testimonial}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // const handleShiftLeft = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonial.length) % testimonial.length);
    // };

    // const handleShiftRight = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonial.length);
    // };

    const handleShiftRight = useCallback(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonial.length);
  }, [setCurrentIndex, testimonial.length]);
  

    

      const handleShiftLeft = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonial.length);
      }, [testimonial.length]);
    
      // useEffect(() => {
      //   const intervalId = setInterval(handleShiftRight, 5000);
      //   return () => clearInterval(intervalId);
      // }, [handleShiftLeft]);
      useEffect(() => {
        const intervalId = setInterval(handleShiftRight, 5000);
        return () => clearInterval(intervalId);
      }, [handleShiftLeft, handleShiftRight]);
      

      if (!testimonial || testimonial.length === 0) {
        return (
          <>
            <h1 className='h1text'>TESTIMONIALS</h1>
            <p>No testimonials available</p>
          </>
        );
      }

      function truncateText(text, words) {
        const wordArray = text.split(' ');
        const truncatedText = wordArray.slice(0, words).join(' ');
        return truncatedText + (wordArray.length > words ? '' : ''); // Add ellipsis if truncated
      }
      

    return (
        <>
        <br/>
            <h1 className='h1text'>TESTIMONIALS</h1>
            <div className="testimonial-div">
                <div className="TChild">
                            <img src={`${urlApi}/${testimonial[currentIndex].img}`} alt='amrit' className='img'/>
                            <p>{testimonial[currentIndex].name}</p>
                            {testimonial[currentIndex].text.split('.').map((sentence, index) => (
                            <p key={index} className='text-tmonial'>
                              {truncateText(sentence, 16)}
                            </p>
                            ))}
                </div>
                  <TfiShiftLeftAlt  className='right-shift3' onClick={handleShiftLeft}/>
                  <TfiShiftRightAlt className='left-shift3' onClick={handleShiftRight}/>
            </div>
        </>
    )
}
export default TestimonialChild;