// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent-container {
  display: flex;
  gap: 18px;
  overflow: hidden;
}

.BannerSideContentChild {
  display: flex;
  flex: 0 0 300px;
  flex-direction: column;
}

.BannerSideContent2 {
  flex: 1 1;
  min-width: 200px; /* Set your desired minimum width */
}

@media screen and (max-width: 480px) {
  .parent-container {
    display: flex;
    gap: 1rem;
    flex-direction: column-reverse;
  }
  .BannerSideContentChild {
    display: flex;
    flex: 0 0 300px;
    flex-direction: column;
  }
  .BannerSideContent2 {
    flex: 1 1;
    min-width: 200px; /* Set your desired minimum width */
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .parent-container {
    display: flex;
    gap: 1rem;
    flex-direction: column-reverse;
  }
  .BannerSideContentChild {
    display: flex;
    flex: 0 0 300px;
    flex-direction: column;
  }
  .BannerSideContent2 {
    flex: 1 1;
    min-width: 200px; /* Set your desired minimum width */
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/BannerSideContent/BannerSideContent.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,gBAAA;AACJ;;AAEE;EACE,aAAA;EACA,eAAA;EACA,sBAAA;AACJ;;AAEE;EACE,SAAA;EACA,gBAAA,EAAA,mCAAA;AACJ;;AAKE;EACE;IACE,aAAA;IACA,SAAA;IACA,8BAAA;EAFJ;EAKE;IACE,aAAA;IACA,eAAA;IACA,sBAAA;EAHJ;EAME;IACE,SAAA;IACA,gBAAA,EAAA,mCAAA;EAJJ;AACF;AAWE;EACE;IACE,aAAA;IACA,SAAA;IACA,8BAAA;EATJ;EAYE;IACE,aAAA;IACA,eAAA;IACA,sBAAA;EAVJ;EAaE;IACE,SAAA;IACA,gBAAA,EAAA,mCAAA;EAXJ;AACF","sourcesContent":[".parent-container {\n    display: flex;\n    gap: 18px;\n    overflow: hidden;\n  }\n  \n  .BannerSideContentChild {\n    display: flex;\n    flex: 0 0 300px;\n    flex-direction: column;\n  }\n  \n  .BannerSideContent2 {\n    flex: 1;\n    min-width: 200px; /* Set your desired minimum width */\n  }\n\n\n  // Media Query for all screen\n  \n  @media screen and (max-width: 480px) {\n    .parent-container {\n      display: flex;\n      gap: 1rem;\n      flex-direction: column-reverse;\n    }\n\n    .BannerSideContentChild {\n      display: flex;\n      flex: 0 0 300px;\n      flex-direction: column;\n    }\n    \n    .BannerSideContent2 {\n      flex: 1;\n      min-width: 200px; /* Set your desired minimum width */\n    }\n  \n\n    \n\n  }\n\n  @media screen and (min-width: 481px) and (max-width: 767px) {\n    .parent-container {\n      display: flex;\n      gap: 1rem;\n      flex-direction: column-reverse;\n    }\n\n    .BannerSideContentChild {\n      display: flex;\n      flex: 0 0 300px;\n      flex-direction: column;\n    }\n    \n    .BannerSideContent2 {\n      flex: 1;\n      min-width: 200px; /* Set your desired minimum width */\n    }\n  \n\n    \n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
