import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./BottomNavChild.scss";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineLogin } from "react-icons/ai";
import Flag from "./flag.gif";
import { RxCross1 } from "react-icons/rx";
import BuildingImage from './MUBuilding.jpg';
import { urlApi } from "../Context/url";
import OurTeam from "./ourTeam";
import muflag from './muflag.gif';

const BottomNavChild = ({ bottomNav }) => {
  const [showNav, setShowNav] = useState(false);
  const [pop, setPop] = useState(false);
  const [hoveredChild, setHoveredChild] = useState(null);
  const [content, setContent] = useState();

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const handleMouseEnter = (child) => {
    setHoveredChild(child);
    setPop(false);
  };

  const handleMouseLeave = () => {
    setHoveredChild(null);
    setPop(false);
  };

  const closeButton = () => {
    setPop(false);
    setContent(false)
  }

  const childConent = (data) => {
    // alert(data.about.id)
    setPop(true)
    setContent(data);
  }

  return (
    <>
      <header className={`header ${showNav ? "show-nav" : ""}`} id="bbn">
        <div className="toggle-icon" onClick={toggleNav}>
          {showNav ? <FaTimes /> : <FaBars />}
        </div>
        <nav className={`nav ${showNav ? "show" : ""}`}>
          {/* <Link to="/" className="headerchild3">
            Home
          </Link> */}

          {bottomNav.map((data, index) => {
            // Check if any child's parent ID matches the current data ID
            const shouldDisplay = data.children && data.children.some(
              (child) => child.parent === data.id
            );

            // Render the menu item only if shouldDisplay is true
            if (shouldDisplay) {
              return (
                <React.Fragment key={index}>
                  <div
                    className="menu-item"
                    onMouseEnter={() => handleMouseEnter(data)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="headerchild">
                      {data.name}
                      <IoIosArrowDown className="arrow-icon" />

                    {/* {data.children && data.children.map((child) => (
                      <div key={child}>
                        {child.dept_title}
                      </div>
                    ))} */}

                    </div>
                    <div className={`navbarcontent ${hoveredChild === data ? "show" : ""}`}>
                      {data.children && data.children.map((child, childIndex) => (
                        <div
                          key={childIndex}
                          onMouseEnter={() => handleMouseEnter(child)}
                          onMouseLeave={handleMouseLeave}
                          onClick={()=> childConent(child)}
                        >
                          {child.name}
                          {/* {child.dept_title}
                          {child.dept_img}
                          <img src={`${urlApi}/${child.dept_img}`} alt='name'/>
                           */}
                        </div>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              );
            } else {
              return null; // Skip rendering the menu item
            }
          })}

          {/* <Link to="https://mwu.appdatamap.com/" className="headerchild">
            MUGSDS
          </Link>
          <Link to="/" className="headerchild1">
            <IoIosArrowDown />
          </Link> */}
          {/* <Link to="/login" className="headerchild">
            Login
          </Link>
          <Link to="/" className="headerchild1">
            <AiOutlineLogin />
          </Link> */}
        </nav>
        <img src={Flag} alt="flag" className="flag" />
        <img src={muflag} alt="flag" className="flag1" />
      </header>

      {content && (
        <div className="overlay" onClick={handleMouseLeave}>
          {/* <img src={BuildingImage} alt="hi"  className="imginsidediv"/> */}
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <span onClick={closeButton}>
              <RxCross1 className="crossbtn"/>
            </span>
           <span className="htext-keep-moving"><p>{content.name}</p></span>
           {  /*           
             {content.name === 'Our Team' && content.dept && content.dept.map((data) => (
              <>
              <OurTeam data={data}/>
              </>
             ))} */}
             <OurTeam data={content}/>
            
            
            <p dangerouslySetInnerHTML={{ __html: content.content }}></p>
          </div>
        </div>
      )}
      
    </>
  );
};

export default BottomNavChild;
