import { useEffect, useState } from "react";
import EventChild from "./EventChild";
import { urlApi } from "../Context/url";

const Event = () => {
    const [event, setEvent] = useState([]);

    useEffect(()=> {
        const fetchData = async() => {
            try {
            const response = await fetch(`${urlApi}/upevents/`);
            const result = await response.json();
            setEvent(result);
        } catch(error) {
            console.log('Error: ', error)
        }
    }
        fetchData();
    }, [])

    return (
        <>
            <EventChild event={event}/>
        </>
    )
}
export default Event;