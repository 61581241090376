import { useEffect, useState } from "react";
import UniversityCampusesItems from "./UniversityCampusesItems";
import { urlApi } from "../Context/url";

const UniversityCampuses = () => {
    const [udata, setUdata] = useState([]);

    useEffect(()=> {
        const fetchData = async() => {
            try {
                const response = await fetch(`${urlApi}/universitycampuses/`);
                const result = await response.json();
                setUdata(result);
            } catch(error) {
                console.log('Error :', error)
            }
        }
        fetchData();
    }, [])


    return (
        <>
            <UniversityCampusesItems udata={udata}/>
        </>
    )
}
export default UniversityCampuses;