import { ToastContainer } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";

const FacultiesChild = ({handleContentChange, handleSubmit, formData, currentBanners,
    handleContentChange1,
    putHandler,
    deleteHandler,
    put,
    putBanner,
    formData1,
    showbanner,
    currentPage,
    bannersPerPage,
    generatePaginationButtons,
    paginate,}) => {
    return (
        <>        <div className="gallery-container">
        <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Enter Name" name="name" value={formData.name} onChange={handleContentChange} className="tt"/>
            <input type="text" placeholder="Enter Designation" name="designation" value={formData.designation} onChange={handleContentChange} className="tt"/>
            <input type="file" accept="image/*" name="img" onChange={handleContentChange} className="tt"/>
            <button type="submit">Add</button>
        </form>
        <ToastContainer />
        </div>
        {/* start */}
        <article className="article-height">
                {currentBanners.map((data, index) => (
                    <div className="main-content">
                        <div className="child-main-container">
                            <div className="cont1">
                                <span>{index + 1}. </span>
                                <span>{data.name}</span>
                            </div>
                            <div className="cont2">
                                <FaEye /> <FaRegEdit onClick={() => putHandler(data)} />{" "}
                                <RiDeleteBin5Fill onClick={() => deleteHandler(data.id)} />
                            </div>
                        </div>
                    </div>
                ))}
            </article>
            {put.id && (
                <div className="main-conatiner" id="main">
                    <div className="child-container" id="child">
                        <input
                        type="text"
                        name="name1"
                        value={formData1.name1 || put.name}
                        onChange={handleContentChange1}
                        className="tt"
                    />
                    </div>
                    <div className="child-container" id="child">
                        <input
                        type="text"
                        placeholder="Enter Designation"
                        name="designation1"
                        value={formData1.designation1 || put.designation}
                        onChange={handleContentChange1}
                        className="tt"
                    />
                    </div>
                    <div className="child-container" id="child">
                    <input
                        type="file"
                        name="img1"
                        onChange={handleContentChange1}
                        className="form-input form-file-input"
                        required
                    />
                    </div>
                    <button id="bnbtn" onClick={putBanner}>
                        Save Content
                    </button>
                    <ToastContainer />
                </div>
            )}

            {/* Pagination controls */}
            <div className="pagination">
                {showbanner.length > bannersPerPage && (
                    <>
                        {currentPage > 1 && (
                            <button onClick={() => paginate(currentPage - 1)}>
                                Previous
                            </button>
                        )}
                        {generatePaginationButtons()}
                        {currentPage < Math.ceil(showbanner.length / bannersPerPage) && (
                            <button onClick={() => paginate(currentPage + 1)}>Next</button>
                        )}
                    </>
                )}
            </div>

            {/* end */}
        </>
    )
}
export default FacultiesChild;