import { useEffect, useState } from 'react';
import { urlApi } from '../Context/url';
import BottomNavChild from './BottomNavChild';

const BottomNav = () => {
    const [bottomNav, setBottomNav] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${urlApi}/menu/`);
                const result = await response.json();
                setBottomNav(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    

    return (
       <>
        <BottomNavChild bottomNav={bottomNav}/>
       </>
    )
}
export default BottomNav;