import { useEffect, useState } from "react";
import WhyChooseUsChild from "./WhyChooseUsChild";
import { urlApi } from "../Context/url";

const WhyChooseUs = () => {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);


    useEffect(()=> {
        const fetchData = async() => {
            try {
                const response = await fetch(`${urlApi}/whychooseus/`);
                const result = await response.json()
                setData(result);
            } catch (error) {
                console.log('Error fetching data', error)
            }
        }
        fetchData();
    }, [])

    useEffect(()=> {
        const fetchData2 = async() => {
            try {
                const response = await fetch(`${urlApi}/blog/`);
                const result = await response.json()
                setData2(result);
            } catch(error) {
                console.log('Error fetching data', error)
            }
        }
        fetchData2();
    }, [])
    

    return (
        <>
            <WhyChooseUsChild data={data} data2={data2}/>
        </>
    )
}
export default WhyChooseUs;