import './FooterChild.scss';
const FooterChild = () => {
    return (
        <>
        <br/>
           {/* <div className="footer-div">
                <div className="footer">
                    <h2>Contact Information</h2>
                    <p>📌 Birendranagar 09, Surkhet</p>
                    <p>📞 083-524681 / 525333</p>
                    <p>🌍 info@mupolicy.edu.np</p>
                </div>
                <hr></hr>
                <div className="footer">
                    <h2>Quick links</h2>
                    <p>Events</p>
                    <p>Blog</p>
                    <p>Image Gallery</p>
                </div>
                <hr></hr>
                
                <div className='footer'>
                    <h2>Useful Links</h2>
                    <p>
                    <a href="https://www.ugcnepal.edu.np/frontpage/20" target="_blank" rel='noreferrer'>Universities of Nepal</a>
                    </p>
                    <p>
                    <a href="https://www.ugcnepal.edu.np/frontpage/20" target="_blank" rel='noreferrer'>University Grand Commission</a>
                    </p>
                    <p>
                    <a href="https://mocit.gov.np/" target="_blank" rel='noreferrer'>Ministry of Information and Communications</a>
                    </p>
                    <p>
                    <a href="https://mof.gov.np/" target="_blank" rel='noreferrer'>Ministry of Finance</a>
                    </p>
                </div>
                
            </div> */}
            <div className='bkclolor'>
            <span className='algn'>© 2024 Mid-West University, Nepal | All Rights Reserved. Coded by Amrit Shahi</span>
            </div>
        </>
    )
}
export default FooterChild;
