import React, { useEffect, useState } from 'react';
import Childpdf from './childpdf'
import { urlApi } from '../Context/url';

const ParentPdf = () => {
  const [data, setData] = useState(null); // Changed initial state to null
  console.log(data)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${urlApi}/calender/`); // Corrected endpoint
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error); // Improved error logging
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      {data ? (
        <Childpdf data={data} />
      ) : (
        <p>Loading...</p> // Display a loading message while data is being fetched
      )}
    </div>
  );
};

export default ParentPdf; // Capitalized component name
