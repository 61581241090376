import React, { useState, useEffect } from 'react';
import { urlApi } from '../../Context/url';
import BottomNavChild from '../../BottomNav/BottomNavChild';
import PageGetList from './PageGetList';

const PageList = () => {
    const [pages, setPages] = useState([]);
    const [newPage, setNewPage] = useState({ title: '', content: '', parent: null });

    useEffect(() => {
        fetchPages();
    }, []);

    const fetchPages = async () => {
        try {
            const response = await fetch(`${urlApi}/api/pages/`);
            const data = await response.json();
            setPages(data);
        } catch (error) {
            console.error('Error fetching pages:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewPage({ ...newPage, [e.target.name]: e.target.value });
    };

    const handleCreatePage = async (e) => {
        e.preventDefault();
        try {
            // Ensure parent is set to an empty list if not selected
            const parentValue = newPage.parent ? [newPage.parent] : [];
    
            await fetch(`${urlApi}/api/pages/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...newPage, parent: parentValue }),
            });
    
            setNewPage({ title: '', content: '', parent: null });
            fetchPages();  // Refresh the page list after creating a new page
        } catch (error) {
            console.error('Error creating page:', error);
        }
    };
    

    return (
        <div>
            <PageGetList pages={pages}/>
            <BottomNavChild />
            <form onSubmit={handleCreatePage}>
                <label>Title:
                    <input type="text" name="title" value={newPage.title} onChange={handleInputChange} />
                </label>
                <br />
                <label>Content:
                    <textarea name="content" value={newPage.content} onChange={handleInputChange} />
                </label>
                <br />
                <label>Parent:
                <select name="parent" value={newPage.parent || ''} onChange={handleInputChange}>
                    <option value="">None</option>
                    {pages.map(page => (
                        <option key={page.id} value={page.id}>{page.title}</option>
                    ))}
                </select>
            </label>
                <br />
                <button type="submit">Create Page</button>
            </form>
        </div>
    );
};

export default PageList;
