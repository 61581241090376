import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urlApi } from "../Context/url";
import { useEffect, useState } from 'react';
import './Banner.scss';
import { getToken } from './LocalStorageService';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEye } from "react-icons/fa6";




const Banner = () => {
    const [img, setImg] = useState(null);
    const [header, setHeader] = useState(''); // Added state for header
    const [text, setText] = useState('');   // Added state for text
    // for put
    const [img1, setImg1] = useState(null);
    const [header1, setHeader1] = useState(''); // Added state for header
    const [text1, setText1] = useState('');   // Added state for text

    const [showbanner, setShowBanner] = useState([])
    const [put, setPut] = useState([])
    
    // for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const bannersPerPage = 6; // You can adjust this based on your preference

    
  // for pagination
  // Calculate indexes for pagination
  const indexOfLastBanner = currentPage * bannersPerPage;
  const indexOfFirstBanner = indexOfLastBanner - bannersPerPage;
  const currentBanners = showbanner.slice(indexOfFirstBanner, indexOfLastBanner);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  //  
// Add this function to generate pagination buttons dynamically
const generatePaginationButtons = () => {
  const totalPages = Math.ceil(showbanner.length / bannersPerPage);
  const visiblePages = 18; // Number of visible pagination buttons

  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > visiblePages) {
    const half = Math.floor(visiblePages / 2);
    startPage = Math.max(currentPage - half, 1);
    endPage = Math.min(startPage + visiblePages - 1, totalPages);
    if (endPage - startPage + 1 < visiblePages) {
      startPage = endPage - visiblePages + 1;
    }
  }

  const buttons = [];
  for (let i = startPage; i <= endPage; i++) {
    buttons.push(
      <button
        key={i}
        onClick={() => paginate(i)}
        style={{ fontWeight: currentPage === i ? 'bold' : 'normal', color: currentPage === i ? 'red' : 'white', }}
      >
        {i}
      </button>
    );
  }

  return buttons;
};






  // put request
  const putHandler = (data) => {
    setPut(data);
    // putBanner(data.id);
  }

  const handleFileChange1 = (e) => {
    setImg1(e.target.files[0]);
  };

  const putBanner = async () => {
    const accessToken = getToken().access_token
    try {
      const formData = new FormData();
      // formData.append('img', img1);
      // formData.append('header', header1); // Append header value
      // formData.append('text', text1);   // Append text value
      if(img1) {
        formData.append('img', img1)
      }
      if(header1.trim() !== '') {
        formData.append('header', header1 || '')
      }
      if(text1.trim() !== '') {
        formData.append('text', text1)
      }

      const response = await fetch(`${urlApi}/banner/${put.id}/`, {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
      },
      });

      if (response.ok) {
        console.log('Data saved successfully');
        notifySuccess('Saved!');
         // Clear the form fields after a successful submission
         setHeader1('');
         setText1('');
         setImg1(null);
         // Update the state with the modified banner
         const updatedShowBanner = showbanner.map(item =>
          item.id === put.id ? { ...item, header: header1, text: text1 } : item
          );
          
          setShowBanner(updatedShowBanner);
          setPut([]); // Clear the 'put' state
          await fetchBanners(); // Fetch banners after deleting to update the list

      } else {
        // const errorMessage = await response.text();
        // console.error('Error saving data:', errorMessage);
        // notifyError('Required fields not filled.', errorMessage);
        const errorMessage = await response.text();
        console.error('Error saving data:', errorMessage);
        notifyError('Required fields not filled.', errorMessage);
      }
    } catch (error) {
      console.error('Error saving data:', error.message);
      notifyError('Error saving data', error.message);
    }
        
      }



  // Delete handler
  const deleteHandler = (data) => {
    deleteBanner(data);
  }

  // for delete banner

  const deleteBanner = async (id) => {
    const accessToken = getToken().access_token
    try {
      const response = await fetch(`${urlApi}/banner/${id}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
      },
      });
      if (response.ok) {
        console.log('Data deleted successfully');
        notifySuccess('Deleted!');
        fetchBanners(); // Fetch banners after deleting to update the list
      } else {
        const errorMessage = await response.text();
        console.error('Error saving data:', errorMessage);
        notifyError('Required fields not filled.', errorMessage);
      }
    } catch (error) {
      console.error('Error deleting data:', error.message);
      notifyError('Error deleting data', error.message);
    }
        
      }
    

  // for get request
  const fetchBanners = async () => {
    try {
      const response = await fetch(`${urlApi}/banner/`);
      if(response.ok) {
        const data = await response.json();
        setShowBanner(data);
        
        
      } else {
        console.log('Error fetching banners')
      }
    } catch(error) {
      console.log('Error fetching banners:', error.message);
    }
  }

  useEffect(() => {
    const fetchAndSetBanners = async () => {
      await fetchBanners();
      setCurrentPage(1);
    };
    fetchAndSetBanners();
  }, []);


    const handleFileChange = (e) => {
        setImg(e.target.files[0]);
      };
      const handleSaveClick = async () => {
        //const accessToken = localStorage.getItem('accessToken');
        const accessToken = getToken().access_token
        try {
          const formData = new FormData();
          formData.append('img', img);
          formData.append('header', header); // Append header value
          formData.append('text', text);   // Append text value
    
          const response = await fetch(`${urlApi}/banner/`, {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Bearer ${accessToken}`,
          },
          });
    
          if (response.ok) {
            console.log('Data saved successfully');
            notifySuccess('Saved!');
               // Clear the form fields after a successful submission
          setHeader('');
          setText('');
          // setContent('');
          setImg(null);
          await fetchBanners();

          } else {
            const errorMessage = await response.text();
            console.error('Error saving data:', errorMessage);
            notifyError('Required fields not filled.', errorMessage);
          }
        } catch (error) {
          console.error('Error saving data:', error.message);
          notifyError('Error saving data', error.message);
        }
      };
      
  const notifySuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>    
    <br/>
    <div className='main-conatiner'>
        <div className='child-container'>
      <textarea
        type="text"
        value={header}
        onChange={(e) => setHeader(e.target.value)}
        className="form-input"
        placeholder='Header: No more than 30 characters only'
      />
      </div>
      <div className='child-container'>
      <textarea
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="form-input"
        placeholder='Long Header: No more than 200 characters only'
      />
    </div>
    <div className='child-container'>
      <input
        type="file"
        onChange={handleFileChange}
        className="form-input form-file-input"
        required
      />
      </div>
      <div className='btncontainer'>
      <button onClick={handleSaveClick} id="bnbtn">
        Save Content
      </button>
      <ToastContainer />
    </div>
    </div>
    <article className='article-height'>
      {currentBanners.map((data, index)=> <div className='main-content'>
        <div className='child-main-container'>
          <div className='cont1'>
          <span>{index + 1}. </span>
          <span>{data.header}</span> 
          </div>
          <div className='cont2'>
          <FaEye /> <FaRegEdit onClick={()=> putHandler(data)}/> <RiDeleteBin5Fill onClick={()=> deleteHandler(data.id)}/>
          </div>
          </div>
      </div>)}
      </article>
      {put.id && (
        <div className='main-conatiner'>
        <div className='child-container'>
      <textarea
        type="text"
        name='header1'
        value={header1 || put.header}
        onChange={(e) => setHeader1(e.target.value)}
        className="form-input"
        placeholder='Header: No more than 30 characters only'
      />
      </div>
      <div className='child-container'>
      <textarea
        type="text"
        name='text1'
        value={text1 || put.text}
        onChange={(e) => setText1(e.target.value)}
        className="form-input"
        placeholder='Long Header: No more than 200 characters only'
      />
    </div>
    <div className='child-container'>
      <input
        type="file"
        name='img1'
        onChange={handleFileChange1}
        className="form-input form-file-input"
        required
      />
      </div>
     
      <button  id="bnbtn" onClick={putBanner}>
        Save Content
      </button>
      <ToastContainer />
    
    </div>
      )}


{/* Pagination controls */}
<div className="pagination">
  {showbanner.length > bannersPerPage && (
    <>
      {currentPage > 1 && (
        <button onClick={() => paginate(currentPage - 1)}>
          Previous
        </button>
      )}
      {generatePaginationButtons()}
      {currentPage < Math.ceil(showbanner.length / bannersPerPage) && (
        <button onClick={() => paginate(currentPage + 1)}>
          Next
        </button>
      )}
    </>
  )}
</div>



    
    </>

    )
}
export default Banner;