import React, { useState } from 'react';
import { urlApi } from "../Context/url";
import Speech from 'react-speech';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FileUploadComponent = () => {
  const [file, setFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pdfText, setPdfText] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [speak, setSpeak] = useState(false);
  const[pdf, setPdf] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setPdfFile(null); // Reset pdfFile when a new file is selected
    setNumPages(null); // Reset numPages when a new file is selected
  };

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('pdf_file', file);
      setPdf(file);
      const response = await fetch(`${urlApi}/upload/`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.text) {
          setPdfText(data.text);
          setPdfFile(file);
          setShowPopup(true);
        } else {
          console.error('Failed to convert PDF to text or no text found');
        }
      } else {
        console.error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleSpeakButtonClick = () => {
    setSpeak(!speak);
  };

  return (
    <div>
      <input type="file" accept=".pdf" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload PDF</button>

      {pdfFile && (
        <div className="pdf-viewer">
          <Document file={URL.createObjectURL(pdf)} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      )}

      {showPopup && (
        <div className="popup">
          <button onClick={handleClosePopup}>Close</button>
          {/* Separate button to trigger text-to-speech */}
          <button onClick={handleSpeakButtonClick}>Speak</button>
          {/* Only show react-speech component if not speaking and pdfFile exists */}
          {!speak && pdfFile && <Speech text={pdfText} voice="hi-IN" />}
        </div>
      )}

      {pdfFile && numPages && (
        <p>
          PDF File: {pdfFile.name}, Number of Pages: {numPages}
        </p>
      )}
    </div>
  );
};

export default FileUploadComponent;
