import { useEffect, useState } from "react";
import Child from "./Child";
import { urlApi } from "../Context/url";

const Download = () => {
    const [data, setData] = useState([]);

    useEffect(()=> {
        const fetchData = async () => {
            try {
                const response = await fetch (`${urlApi}/downloads/`);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.log('Error fetching data: ', error);
            }
        };
        fetchData();
    }, [])
    return <Child data={data}/>
}
export default Download;