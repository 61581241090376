import { useEffect, useState } from 'react';
import './EndBannerChild.scss';
import cover from './cover.jpg';
import { urlApi } from '../Context/url';

const EndBannerChild = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${urlApi}/onebanner/`);
                const result = await response.json(); // Await the result
                setData(result);

            } catch (error) {
                console.log('Error loading', error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="endbannercontainer">
                <p className='pclass'>EDUCATION IS FOR EVERYONE</p>
                {data.length > 0 && (
                    <img src={`${urlApi}/${data[0].img}`} alt='img' className='bannendimg'/>
                )}
            </div>
        </>
    );
};

export default EndBannerChild;
