import BannerSideContent2 from "./BannerSideContent2";
import BannerSideContentChild from "./BannerSideContentChild";
import './BannerSideContent.scss'
import { useEffect, useState } from "react";
import { urlApi } from "../Context/url";

const BannerSideContent = () => {
    const [banner, setBanner] = useState([]);
    const [bannerchild, setBannerchild] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${urlApi}/banner/`);
                const result = await response.json();
                setBanner(result);
            } catch(error) {
                console.log('Error fetching data: ', error);
            }
        };

        const fetchChild = async () => {
            try {
                const response = await fetch(`${urlApi}/bannerchild/`);
                const result = await response.json();
                setBannerchild(result);
            } catch(error) {
                console.log('Error fetching bannerchild data: ', error);
            }
        };
        // fetch both sets of data
        fetchData();
        fetchChild();
    }, [])


    return (
        <>
        <div className="parent-container">
            <BannerSideContentChild class="BannerSideContentChild" bannerchild={bannerchild}/>
            <BannerSideContent2 class="BannerSideContent2" bannerdata={banner}/>
        </div>

        </>
    )
}
export default BannerSideContent;