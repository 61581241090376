import { useEffect, useState } from 'react';
import { urlApi } from '../Context/url';
import TopNavChild from './TopNavChild';

const TopNav = () => {
    const [topNav, setTopNav] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(urlApi);
                const result = await response.json();
                setTopNav(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
       <>
        <TopNavChild topNav={topNav}/>
       </>
    )
}
export default TopNav;