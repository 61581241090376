import BottomNav from "./Components/BottomNav/BottomNav";
import MiddleNav from "./Components/MiddleNav/MiddleNav";
import TopNav from "./Components/TopNav/TopNav";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
// import AdminPanel from "./Components/Admin/AdminPanel";
import Quill from "./Components/Admin/UpcommingEvents/Editor";
import FileUploadComponent from "./Components/FileUploadComponent/FileUploadComponent";
import Login from "./Components/Admin/Login/Login";
import { ToastContainer } from 'react-toastify';
import Download from "./Components/Downloads/Downloads";
import PageList from "./Components/Admin/PageList/PageList";
import './app.scss';

function App() {
  return (
    <BrowserRouter>
    <ToastContainer/>
      <div className="App">
        <TopNav />
        <MiddleNav />
        <BottomNav />
        <Routes>
          {/* Route for login page */}
          <Route exec path="/" element={<Home/>}/>
          {/* <Route exec path="/admin" element={<AdminPanel/>} /> */}
          <Route exec path='/login' element={<Login/>}/>
          <Route exec path="/editor" element={<Quill/>}/>
          <Route exec path="/study" element={<FileUploadComponent/>}/>
          <Route exec path="/downloads" element={<Download/>}/>
          <Route exec path="/page" element={<PageList/>}/>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;